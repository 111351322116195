import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import faqs from '../../shared/constants/faqs.json';

@Injectable()
export class FaqService {
    tutorial;
    element;
    context;
    listen;
    indexCurrentElement = 0;
    backdrop;

    private faqSubject$: BehaviorSubject<boolean>;
    private renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.faqSubject$ = new BehaviorSubject<boolean>(false);
    }

    state$(): Observable<boolean> {
        return this.faqSubject$.asObservable();
    }

    state(): boolean {
        return this.faqSubject$.value;
    }

    toggle(module): void {
        if (!this.state()) {
            this.enable(module);
        } else {
            this.disable();
        }
    }

    enable(module): void {
        this.tutorial = faqs.find(faq => faq.module === module);
        if (!this.tutorial) {
            return;
        }
        this.faqSubject$.next(true);
        this.indexCurrentElement = 0;
        this.context = this.tutorial.elements[this.indexCurrentElement];
        this.element = document.getElementById(this.context.id);
        this.appendBackdrop();
        this.createPopover();
    }

    disable(): void {
        const $ = window['$'];
        $(this.element).popover('dispose');
        this.element = null;
        this.indexCurrentElement = 0;
        this.removeBackdrop();
        this.faqSubject$.next(false);
    }

    createPopover(): void {
        const $ = window['$'];
        if (!this.element) {
            this.next();
            return;
        }
        this.element.setAttribute('data-toggle', 'popover');
        this.element.setAttribute('data-html', 'true');
        this.element.setAttribute('data-placement', 'bottom');
        this.element.scrollIntoView({ block: 'end', behavior: 'smooth' });
        const content = this.renderer.createElement('div');
        const text = this.renderer.createText(this.context.content);
        const btnGroups = this.renderer.createElement('div');
        const btnNext = this.renderer.createElement('btn');
        const textBtnNext = this.renderer.createText('Entiendo');
        const btnDisabled = this.renderer.createElement('btn');
        const checkDisabled = this.renderer.createElement('input');
        const textbtnDisabled = this.renderer.createText('Desactivar');
        const pagination = this.renderer.createElement('div');
        this.renderer.setAttribute(content, 'id', 'module-faq');
        this.renderer.setAttribute(checkDisabled, 'type', 'checkbox');
        this.renderer.addClass(checkDisabled, 'me-2');
        this.renderer.addClass(btnGroups, 'd-flex');
        this.renderer.addClass(btnGroups, 'justify-content-between');
        this.renderer.addClass(btnGroups, 'text-secondary');
        this.renderer.addClass(btnGroups, 'mt-3');
        this.renderer.addClass(content, 'text-size-5');
        this.renderer.addClass(content, 'text-color-primary');
        this.renderer.addClass(content, 'p-2');
        this.renderer.addClass(pagination, 'd-flex');
        this.renderer.addClass(pagination, 'justify-content-between');
        this.renderer.addClass(pagination, 'mt-2');
        this.renderer.addClass(btnNext, 'me-1');
        this.renderer.addClass(btnNext, 'cursor-pointer');
        this.renderer.addClass(btnDisabled, 'cursor-pointer');
        this.renderer.setStyle(content, 'width', '250px');
        this.renderer.appendChild(btnNext, textBtnNext);
        this.renderer.appendChild(btnDisabled, checkDisabled);
        this.renderer.appendChild(btnDisabled, textbtnDisabled);
        this.renderer.appendChild(btnGroups, btnDisabled);
        this.renderer.appendChild(btnGroups, btnNext);
        this.listen = this.renderer.listen(btnNext, 'click', this.next.bind(this));
        this.listen = this.renderer.listen(btnDisabled, 'click', this.disable.bind(this));
        this.tutorial.elements.forEach((_, index) => {
            const itemPagination = this.renderer.createElement('div');
            this.renderer.setStyle(itemPagination, 'background-color', 'var(--bg-color-medium)');
            this.renderer.addClass(itemPagination, 'w-100');
            this.renderer.addClass(itemPagination, 'me-1');
            this.renderer.setStyle(itemPagination, 'height', '4px');
            if (index <= this.indexCurrentElement) {
                this.renderer.setStyle(itemPagination, 'background-color', 'var(--text-color-menu-active)');
            }
            this.renderer.appendChild(pagination, itemPagination);
        });
        this.renderer.appendChild(content, text);
        this.renderer.appendChild(content, btnGroups);
        this.renderer.appendChild(content, pagination);
        $(() => {
            $(this.element).popover({
                html: true,
                content
            });
            $(this.element).popover('show');
        });
    }

    appendBackdrop(): void {
        this.backdrop = this.renderer.createElement('div');
        this.renderer.addClass(this.backdrop, 'modal-backdrop');
        this.renderer.addClass(this.backdrop, 'fade');
        this.renderer.addClass(this.backdrop, 'show');
        this.renderer.addClass(document.body, 'modal-open');
        this.renderer.appendChild(document.body, this.backdrop);
    }

    removeBackdrop(): void {
        this.renderer.removeChild(document.body, this.backdrop);
        this.renderer.removeClass(document.body, 'modal-open');
    }

    next(): void {
        const $ = window['$'];
        $(this.element).popover('dispose');
        this.indexCurrentElement = this.indexCurrentElement + 1;
        this.listen();
        if (this.indexCurrentElement < this.tutorial.elements.length) {
            this.context = this.tutorial.elements[this.indexCurrentElement];
            this.element = document.getElementById(this.context.id);
            this.createPopover();
        } else {
            this.element = document.getElementById(this.context.id);
            this.disable();
        }
    }
}
