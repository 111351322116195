import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Notification } from '../../shared/models/notification.model';
import { catchError, map } from 'rxjs/operators';
import { ViewService } from './view.service';

export interface NotificationSettedAsReaded {
    CodigoError: string;
    Descripcion: string;
}

@Injectable()
export class NotificacionesService implements OnDestroy {
    private _basePath = this._envService.env.BaseUrlService + '/notificaciones';

    private _onNotificationsChange = new BehaviorSubject<Notification[]>([]);
    private _intervalNotifSub!: Subscription;
    private _currentNotifSub!: Subscription;

    /** Obtiene las notificaciones actuales. */
    get currentNotifications(): Notification[] {
        return this._onNotificationsChange.getValue();
    }

    constructor(
        private _http: HttpClient,
        private _viewService: ViewService,
        private _envService: EnvironmentService
    ) {}

    ngOnDestroy(): void {
        this._onNotificationsChange.unsubscribe();
        this._intervalNotifSub.unsubscribe();
    }

    /**
     * (Funcion unica para el resolver) Inicializa el interval para que la
     * actualizacion automatica de las notificaciones.
     */
    initIntervalNotifications(): void {
        this._refreshNotifications(true);
    }

    /**
     * Actualiza el estado de las notificaciones.
     *
     * @param isFirstTime true si se requiere inicializar la actualizacion automatica.
     */
    private _refreshNotifications(isFirstTime: boolean = false): void {
        if (this._currentNotifSub && !this._currentNotifSub.closed) {
            this._currentNotifSub.unsubscribe();
        }
        this._currentNotifSub = this._http
            .get<Notification[]>(`${this._basePath}?avoidAuthRedirect=true`)
            .pipe(
                catchError((err: any) => {
                    const nothing: Notification[] = [];
                    return nothing;
                })
            )
            .subscribe((response: any) => {
                this._onNotificationsChange.next(response.notificaciones);
                this._currentNotifSub.unsubscribe();
                if (isFirstTime) {
                    // FIXME: Modificar el numero hardcodeado con el valor que llegue
                    // de la api (cuando este hecho).
                    const refreshTime = 60000;
                    this._setIntervalNotifications(refreshTime);
                }
            });
    }

    /** Setea el interval para realizar la llamada */
    private _setIntervalNotifications(refreshTime: number): void {
        this._intervalNotifSub = interval(refreshTime).subscribe(() => {
            if (this._viewService.viewActive) {
                this._refreshNotifications();
            }
        });
    }

    /** Retorna un observable con las ultimas notificaciones. */
    getNotificaciones(): Observable<Notification[]> {
        return this._onNotificationsChange.asObservable();
    }

    /** Obtiene las notificaciones historicas. */
    getNotificacionesHistorico(): Observable<Notification[]> {
        return this._http
            .get<Notification[]>(`${this._basePath}?historico=1`)
            .pipe(map((response: any) => response.notificaciones));
    }

    /** Setea una notificacion como leida. */
    setNotificationAsReaded(notificationId: string): Observable<NotificationSettedAsReaded> {
        return this._http.post<NotificationSettedAsReaded>(`${this._basePath}/${notificationId}`, {});
    }

    setNotificationsAllReaded(): Observable<any> {
        return this._http.post<NotificationSettedAsReaded>(`${this._basePath}/-1`, {});
    }
    getDatosAcreditacionDinero(idExtractoBancario) {
        return this._http.get<any>(
            `${this._envService.env.BaseUrlService}/extractos/notificacion/obtenerInfo/${idExtractoBancario}`
        );
    }
    doAcreditacionDinero(payload) {
        return this._http.post<any>(`${this._envService.env.BaseUrlService}/operar/depositos/informar`, payload);
    }
    updateEstado(notificacion) {
        const notificacionesUpdated: any = this.currentNotifications.map(noti => {
            if (noti.idnotificacion === notificacion.idnotificacion) {
                noti.estado = 1;
            }
            return noti;
        });
        this._onNotificationsChange.next(notificacionesUpdated);
    }
}
