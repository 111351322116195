import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InstrumentosService } from '../clients/instrumentos.service';

@Injectable()
export class InstrumentosResolver {
    constructor(private instrumentosService: InstrumentosService) {}
    resolve(): Observable<void> | Promise<void> | void {
        this.instrumentosService.chargeInstrumentos();
    }
}
