import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ModalV2Config } from '../../shared/models/modal-v2.model';

/**
 * Servicio encargado de conectar e informar al componente
 * Modal V2.
 * Para saber mas, visualizar la interfaz "ModalV2Config".
 *
 * ***Ejemplo de utilizacion:***
 * ```typescript
 *
 *  ViewChild('mitemplateref') private _tempRef!: TemplateRef<any>;
 *
 *  constructor(private _modalService: ModalV2Service) {}
 *
 *  // funcion que sera llamada para abrir el modal (En
 *  // caso de necesitar abrirlo al inicio del componente
 *  // hacerlo desde el afterViewInit).
 *  openModal(): void {
 *    this._modalService.open({template: _tempRef});
 *  }
 *  openModalConSuscripcionCierre(): void {
 *    this._modalService.open({template: _tempRef})
 *    .onClose.subscribe((args: any) => {
 *    // args se cargara en caso de que al cerrar el
 *    // modal se llame a la funcion "close" con un
 *    // objeto como parametro.
 *    if (args) {
 *      // do anything
 *    }
 *  });
 *  }
 * ```
 */
@Injectable()
export class ModalV2Service {
    private _onOpen: Subject<ModalV2Config> = new Subject<ModalV2Config>();
    private _onClose: Subject<any> = new Subject<any>();

    /**
     * Imforma de la apertura de un nuevo modal.
     */
    get onOpen(): Observable<ModalV2Config> {
        return this._onOpen.asObservable();
    }

    /**
     * Informa del cierre del ultimo modal abierto,
     * con argumentos (si es que se retorna alguno).
     */
    get onClose(): Observable<any> {
        return this._onClose.asObservable();
    }

    /**
     * Crea un nuevo modal con la configuracion pasada como parametro.
     *
     * @param config configuracion del modal
     * @return Retorna la instancia del onclose para poder suscribirse directamente.
     */
    open(config: ModalV2Config): { onClose: Observable<any> } {
        this._onOpen.next(config);
        return { onClose: this.onClose };
    }

    /**
     * Informa el cierre del ultimo modal.
     *
     * @param args Argumentos que se informaran a los suscriptores en el cierre.
     */
    close(args?: any): void {
        this._onClose.next(args);
    }
}
