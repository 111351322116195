import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Params,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { EnvironmentService } from '../services/environment.service';
import { BzStorageService } from '@balanz/services';
import { sesionCanAccess } from '../../utils/validacion-sesion';

@Injectable()
export class LoggedLayoutGuard implements CanActivate, CanLoad {
    constructor(
        private authService: AuthService,
        private _router: Router,
        private _storageService: BzStorageService,
        private _envService: EnvironmentService
    ) {}

    private _canAccess(): boolean {
        return sesionCanAccess(this.authService);
    }

    canLoad(
        route: Route,
        urlSegments: UrlSegment[]
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this._envService.env.maintenance) {
            if (this._canAccess()) {
                const doForward: string = this._storageService.get('forward');
                const doForwardParams: Params = this._storageService.get('forwardParams');
                if (doForward) {
                    if (doForwardParams) {
                        this._router.navigate([doForward], { queryParams: doForwardParams });
                    } else {
                        this._router.navigate([doForward]);
                    }
                    this._storageService.removeList(['forward', 'forwardParams']);
                    return false;
                }
                return true;
            }
            const auxPath: string = urlSegments.map(u => u.path).join('/');
            const auxParams: Params = this._router.getCurrentNavigation().extractedUrl.queryParams;
            if (auxPath) {
                this._storageService.set('forward', auxPath);
            }
            if (auxParams) {
                this._storageService.set('forwardParams', auxParams);
            }
            this._router.navigate(['/auth']);
            return false;
        } else {
            this._router.navigate(['/maintenance']);
            return false;
        }
    }

    canActivate(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const returnUrl = state.url;
        if (!returnUrl.includes('login' || 'logout')) {
            this._storageService.set('returnUrl', `${returnUrl}`);
        }
        return this._canAccess();
    }
}
