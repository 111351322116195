import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import { VERSION } from 'src/environments/version';
import { AlertsService } from '../services/alerts.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private errorsArray = [500, 404, 400];
    constructor(
        private loggerService: LoggerService,
        private _alertsService: AlertsService,
        private _envService: EnvironmentService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const token = request.headers.get('authorization');
                const url = request.url;
                const params = request.params.toString();
                if (url.includes(`${this._envService.env.BaseUrlLoggerService}/logger-event`)) {
                    return throwError(error);
                }
                this.loggerService.registrarEvento({
                    type: 'http',
                    description: error.message,
                    token,
                    url,
                    status: error.status,
                    method: request.method,
                    module: 'HttpClient',
                    detail: `(${error.error?.CodigoError}) ${error.error?.Descripcion}`,
                    extra: { params },
                    version: VERSION.version + ' ' + VERSION.hash
                });

                const avoidRedirect = request.params.get('avoidAuthRedirect');
                const needAvoid =
                    (avoidRedirect !== null && typeof avoidRedirect === 'boolean' && avoidRedirect === true) ||
                    (typeof avoidRedirect === 'string' && avoidRedirect.toLowerCase() === 'true');

                if (this.errorsArray.includes(error.status) && !needAvoid) {
                    this._alertsService.error(error.error.Descripcion || this._envService.env.defaultErrorMsg);
                }
                return throwError(error);
            })
        );
    }
}
