<div *ngIf="show && !isMobile" class="position-absolute w-100 h-100" [@parentSlideInOut]>
    <!-- contenedor modal -->
    <div
        class="w-100 h-100 position-absolute d-flex pe-none align-items-center"
        [style.z-index]="2"
        [ngClass]="{
            'justify-content-center': config?.type === 'modal',
            'justify-content-end': config?.type === 'sidebar'
        }"
    >
        <div
            class="shadow-lg position-relative pe-auto overflow-hidden bg-white-default"
            [ngClass]="{ 'h-100': config?.type === 'sidebar' }"
            [style.width.px]="getWidth"
            [style.max-width.px]="getWidth"
            [@slideInOut]
        >
            <div
                *ngIf="!config?.hideCancel && !config?.title && !config?.titleIcon"
                class="w-100 fh-8 position-absolute d-flex justify-content-end align-items-center pe-3 pe-none pb-1 pt-2r"
                [style.z-index]="1"
            >
                <div class="d-flex justify-content-center align-items-center position-relative">
                    <p class="mb-0 cursor-pointer pe-auto text-secondary close me-2 mt-1" (click)="close()">
                        &times;
                    </p>
                </div>
            </div>
            <div class="w-100 h-100" [style.max-height.px]="getMaxHeight">
                <div class=" w-100 h-100 bg-white-default" [ngClass]="config?.type === 'sidebar' ? 'p-0' : 'px-4'">
                    <div
                        *ngIf="config?.title || config?.titleIcon"
                        class="   pb-3 justify-content-between"
                        [ngClass]="config?.type === 'sidebar' ? ' pt-2r px-4 sidebar-header ' : 'pt-4 modal-header'"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                *ngIf="config?.titleIcon"
                                [src]="config?.titleIcon"
                                class="me-2"
                                width="16"
                                height="16"
                                alt=""
                            />
                            <span *ngIf="config?.title" class="m-1 text-color-base">{{
                                config?.title | translate
                            }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <button
                                (click)="close()"
                                type="button"
                                class="btn btn-transparent lh-1 p-0 text-secondary fw-semibold opacity-3"
                            >
                                <span aria-hidden="true" class="text-size-12">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div
                        [ngClass]="[
                            config?.title ? 'fh-92 fh-max-92 overflow-y-scroll' : 'h-100',
                            config.backgroundContentModal,
                            config?.type === 'sidebar' && config?.wantModalBody ? 'modal-body-padding' : 'px-0 pt-2r'
                        ]"
                        [class.modal-body]="config.wantModalBody"
                        [class.modal-body-padding-left-right]="!config?.wantModalBody"
                        [class.hide-scrollbar]="config?.hideScrollbar && config?.title"
                    >
                        <ng-template
                            [ngTemplateOutlet]="config.template"
                            [ngTemplateOutletContext]="config?.context"
                        ></ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- background -->
    <div
        *ngIf="config?.showBackground"
        (click)="config.closeOnBackDropClick && close()"
        class="w-100 h-100 position-absolute {{ getOpacity }} {{ getBackColor }} pe-auto"
        [style.z-index]="1"
    ></div>
</div>

<!-- RESPONSIVE -->
<div *ngIf="show && isMobile" class="w-100 h-100 position-absolute overflow-hidden pe-none {{ getBackColor }}">
    <div
        *ngIf="!config?.hideCancel"
        class="w-100 position-absolute d-flex justify-content-end align-items-start pe-3 pt-2"
        [style.z-index]="2"
    >
        <p class="mb-0 pe-auto cursor-pointer" [style.fontSize.rem]="'1.5'" [class.mt-2]="isMobile" (click)="close()">
            &times;
        </p>
    </div>
    <div
        class="w-100 h-100 position-absolute overflow-y-scroll pe-auto"
        [class.hide-scrollbar]="config?.hideScrollbar"
        [style.z-index]="1"
    >
        <div *ngIf="config?.title" class="sidebar-header bg-white-default fh-8 p-3">
            {{ config?.title | translate }}
        </div>
        <div
            [ngClass]="[config?.title ? 'fh-92 fh-max-92 overflow-y-scroll' : 'h-100', config.backgroundContentModal]"
            [class.modal-body]="config.wantModalBody"
            [class.modal-body-padding-left-right]="!config?.wantModalBody"
            [class.hide-scrollbar]="config?.hideScrollbar && config?.title"
        >
            <ng-template [ngTemplateOutlet]="config.template" [ngTemplateOutletContext]="config?.context"></ng-template>
        </div>
    </div>
</div>
