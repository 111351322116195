import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BannersService } from '../clients/banners.service';

@Injectable()
export class BannersResolver implements Resolve<void> {
    constructor(private _bannersService: BannersService) {}

    resolve(): Observable<void> | Promise<void> | void {
        this._bannersService.refreshBanners();
    }
}
