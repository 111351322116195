import { Injectable, Renderer2 } from '@angular/core';

@Injectable()
export class LoadingService {
    private wrapper;

    constructor(private _render: Renderer2) {}

    buildWrapperLoading(): any {
        const wraper = this._render.createElement('div');
        this._render.addClass(wraper, 'position-fixed');
        this._render.addClass(wraper, 'd-flex');
        this._render.addClass(wraper, 'justify-content-center');
        this._render.addClass(wraper, 'align-items-center');
        this._render.addClass(wraper, 'bg-light');
        this._render.setStyle(wraper, 'opacity', '0.4');
        this._render.setStyle(wraper, 'top', '0');
        this._render.setStyle(wraper, 'left', '0');
        this._render.setStyle(wraper, 'width', '100vw');
        this._render.setStyle(wraper, 'height', '100vh');
        this._render.setStyle(wraper, 'z-index', '99999');
        return wraper;
    }

    spinner(options?): { start: () => void; end: () => void } {
        options = {
            size: '3rem',
            color: 'primary',
            ...options
        };
        this.wrapper = this.buildWrapperLoading();
        const loading = this._render.createElement('div');
        this._render.addClass(loading, 'spinner-border');
        this._render.addClass(loading, `text-${options.color}`);
        this._render.setStyle(loading, 'width', options.size);
        this._render.setStyle(loading, 'height', options.size);
        this._render.appendChild(this.wrapper, loading);
        return {
            start: () => {
                this._render.appendChild(document.body, this.wrapper);
            },
            end: () => {
                this._render.removeChild(document.body, this.wrapper);
            }
        };
    }
}
