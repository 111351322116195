import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CuentaService } from 'src/app/core/clients/cuenta.service';
import { DeeplinkService } from 'src/app/core/services/deeplink.service';
import { JWTdecode } from 'src/app/utils/jwt-decode';
import { AuthService } from 'src/app/core/services/auth.service';
import { sesionCanAccess } from 'src/app/utils/validacion-sesion';
import { BzUserBehaviourService } from '@balanz/services';
import { UserBehaviourEventConfig } from 'src/app/shared/models/user-behaviour-event';

@Component({
    selector: 'app-deeplink',
    template: ''
})
export class DeeplinkComponent implements OnInit, OnDestroy {
    eventoptfrom = 'share';
    private _unsubscriber: Subject<void> = new Subject<void>();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _cuentaSvc: CuentaService,
        private _deeplinkService: DeeplinkService,
        private _authService: AuthService,
        private _userBehaviourService: BzUserBehaviourService
    ) {}

    ngOnInit(): void {
        this._route.queryParams.pipe(takeUntil(this._unsubscriber)).subscribe(params => {
            if (params['data']) {
                const parsedData = JWTdecode(params['data']);

                if (parsedData.data.idCampaign) {
                    this._userBehaviourService.campaignId = parsedData.data.idCampaign;

                    const unlogged = !sesionCanAccess(this._authService);

                    const campaignParsedQueryParams = {};

                    Object.keys(params).forEach(key => {
                        campaignParsedQueryParams[key] = encodeURIComponent(params[key]);
                    });

                    const content = {
                        idCampaign: parsedData.data.idCampaign,
                        campaignQueryParams: window.location.search,
                        campaignParsedQueryParams: campaignParsedQueryParams
                    };

                    let event: UserBehaviourEventConfig;

                    if (unlogged) {
                        event = {
                            category: 'campaigns',
                            name: 'campaigns.open_link_unlogged',
                            description:
                                'Event registered when an user clicks on the link of the campaign and arrives to clientes without login',
                            content: content
                        };

                        parsedData.data.campaignData = content;
                    } else {
                        event = {
                            category: 'campaigns',
                            name: 'campaigns.open_link_logged',
                            description:
                                'Event registered when an user clicks on the link of the campaign and arrives to clientes already logged',
                            content: content
                        };
                    }

                    event.behaviourIdConfig = 'new';

                    this._userBehaviourService.sendEvent(event);
                }

                if (parsedData.data.requierelogin.toString() === '1') {
                    if (this._cuentaSvc.getCuentaSeleccionada()) {
                        this._deeplinkService.redirect(parsedData, this.eventoptfrom, true);
                    } else {
                        this.loginAndRedirect(window.btoa(JSON.stringify(parsedData)));
                    }
                } else {
                    this._deeplinkService.redirect(parsedData, this.eventoptfrom, true);
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscriber.next();
        this._unsubscriber.complete();
    }

    loginAndRedirect(link) {
        this._router.navigateByUrl('/auth/login?link=' + link);
    }
}
