import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { CuentaService } from './cuenta.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class CarteraService {
    private _basePath = this._envService.env.BaseUrlService;

    constructor(
        private http: HttpClient,
        private cuentaService: CuentaService,
        private _envService: EnvironmentService
    ) {}

    getEvolucion(idMoneda, tenencia, evento, fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(3, 'months')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http.get(`${this._basePath}/evoluciondecartera/${cuenta.idCuenta}`, {
            params: {
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta,
                idMoneda,
                Tenencia: tenencia,
                Eventos: evento
            }
        });
    }

    getEvolucionTenenciaAgrupada(idMoneda, fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(3, 'months')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http.get(`${this._basePath}/subcartera/evoluciontenenciaagrupada/${cuenta.idCuenta}`, {
            params: {
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta,
                idMoneda
            }
        });
    }
}
