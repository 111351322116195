import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/** Servicio encargado de escuchar los cambios de estado */
@Injectable()
export class ViewService {
    private _viewTab: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    constructor() {
        this._viewTab.next(document.visibilityState === 'visible');
    }

    get viewActive(): boolean {
        return this._viewTab.value;
    }

    get onViewChange(): Observable<boolean> {
        return this._viewTab.asObservable();
    }

    setViewActive(value: boolean): void {
        this._viewTab.next(value);
    }
}
