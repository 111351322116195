import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EnvironmentService } from '../services/environment.service';
import { sesionCanAccess } from '../../utils/validacion-sesion';

@Injectable({ providedIn: 'root' })
export class UnloggedLayoutGuard implements CanActivate, CanLoad {
    constructor(private _authService: AuthService, private _router: Router, private _envService: EnvironmentService) {}

    private _cantAccess(): boolean {
        return !sesionCanAccess(this._authService);
    }

    canLoad(_: Route, __: UrlSegment[]): boolean {
        if (!this._envService.env.maintenance) {
            if (this._cantAccess()) {
                return true;
            }
            this._router.navigate(['/app/home']);
            return false;
        } else {
            this._router.navigate(['/maintenance']);
            return false;
        }
    }

    canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): boolean {
        return this._cantAccess();
    }
}
