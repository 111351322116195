import { Injectable } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { BehaviorSubject, Observable, interval, throwError, timer } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { ViewService } from './view.service';

interface BzWebSocketConfig {
    [key: string]: string | number;
}

interface BzWebSocket {
    webSocket: WebSocketSubject<any>;
    config: BzWebSocketConfig;
    behaviorSubject$: BehaviorSubject<any>;
    retryCounter: number;
    retryTime: number;
}

export const BZ_WEBSOCKET_ERROR = -1;

@Injectable()
export class WebSocketService {
    private _host = this._envService.env.BaseUrlWebSocket;
    private _bzWebSockets: BzWebSocket[] = [];
    private _NUMBERS_OF_WEBSOCKETS = 2;
    private _MAX_RETRY_FOR_CONNECTION = 5;
    private _DELAY_RETRY_FOR_CONNECTION = 30 * 1000;
    private _FALLBACK_INTERVAL = 5 * 60 * 1000;

    constructor(
        private _authService: AuthService,
        private _envService: EnvironmentService,
        private _viewService: ViewService
    ) {
        for (let i = 0; i < this._NUMBERS_OF_WEBSOCKETS; i++) {
            this._bzWebSockets.push({
                webSocket: null,
                config: { panel: 0 },
                behaviorSubject$: new BehaviorSubject<any>(null),
                retryCounter: 0,
                retryTime: this._DELAY_RETRY_FOR_CONNECTION
            });
            this._maintainWebSocketAlive(i);
        }
        this._viewService.onViewChange.subscribe(viewActive => {
            for (let i = 0; i < this._NUMBERS_OF_WEBSOCKETS; i++) {
                if (viewActive) {
                    this._restoreWebSocketConnection(i);
                } else {
                    this._leaveWebSocketIdle(i);
                }
            }
        });
    }

    private _maintainWebSocketAlive(webSocketNumber: number): void {
        const bzWebSocket = this._bzWebSockets[webSocketNumber];
        bzWebSocket.webSocket = webSocket({
            url: this._host,
            protocol: ['Authorization', this._authService.getAccessToken()]
        });
        this._updateWebSocketConnection(webSocketNumber, bzWebSocket.config);

        bzWebSocket.webSocket
            .asObservable()
            .pipe(catchError(e => timer(bzWebSocket.retryTime).pipe(mergeMap(() => throwError(e)))))
            .subscribe({
                next: data => {
                    bzWebSocket.behaviorSubject$.next(data);
                    bzWebSocket.retryCounter = 0;
                },
                error: () => {
                    if (this._viewService.viewActive) {
                        bzWebSocket.behaviorSubject$.next(BZ_WEBSOCKET_ERROR);
                    }
                    if (bzWebSocket.retryCounter++ < this._MAX_RETRY_FOR_CONNECTION) {
                        bzWebSocket.retryTime *= 2;
                        this._maintainWebSocketAlive(webSocketNumber);
                    } else {
                        this._switchToPolling(webSocketNumber);
                    }
                }
            });
    }

    private _switchToPolling(webSocketNumber: number): void {
        const bzWebSocket = this._bzWebSockets[webSocketNumber];
        interval(this._FALLBACK_INTERVAL).subscribe(() => {
            if (bzWebSocket.config.panel !== 0) {
                bzWebSocket.behaviorSubject$.next(BZ_WEBSOCKET_ERROR);
            }
        });
    }

    private _changeWebSocketConnection(webSocketNumber: number, config: BzWebSocketConfig, saveConfig: boolean): void {
        const bzWebSocket = this._bzWebSockets[webSocketNumber];
        if (saveConfig) {
            bzWebSocket.config = config;
        }
        bzWebSocket.webSocket.next({ ...config, token: this._authService.getAccessToken() });
    }

    private _changeWebSocketConnectionToPanel0(webSocketNumber: number, saveConfig: boolean): void {
        const config = { panel: 0 };
        this._changeWebSocketConnection(webSocketNumber, config, saveConfig);
    }

    private _updateWebSocketConnection(webSocketNumber: number, config: BzWebSocketConfig): void {
        this._leaveWebSocketIdle(webSocketNumber);
        this._changeWebSocketConnection(webSocketNumber, config, true);
    }

    private _leaveWebSocketIdle(webSocketNumber: number): void {
        this._changeWebSocketConnectionToPanel0(webSocketNumber, false);
    }

    private _restoreWebSocketConnection(webSocketNumber: number): void {
        const bzWebSocket = this._bzWebSockets[webSocketNumber];
        this._changeWebSocketConnection(webSocketNumber, bzWebSocket.config, false);
    }

    private _disconnectWebSocket(webSocketNumber: number): void {
        this._changeWebSocketConnectionToPanel0(webSocketNumber, true);
    }

    private _connect(topic: string | number, index: string | number, webSocketNumber: number): Observable<any> {
        const bzWebSocket = this._bzWebSockets[webSocketNumber];
        this._updateWebSocketConnection(webSocketNumber, { [topic]: index });

        return bzWebSocket.behaviorSubject$.asObservable().pipe(
            finalize(() => {
                this._disconnectWebSocket(webSocketNumber);
                bzWebSocket.retryTime = this._DELAY_RETRY_FOR_CONNECTION;
            })
        );
    }

    panel(index: string | number): Observable<any> {
        return this._connect('panel', index, 0);
    }

    watchlist(idWatchlist: string | number): Observable<any> {
        return this._connect('idwatchlist', idWatchlist, 0);
    }

    detalleInstrumento(securityId: string | number): Observable<any> {
        return this._connect('securities', securityId, 0);
    }

    recomendacion(securities: string[]): Observable<any> {
        return this._connect('securities', securities.join(','), 1);
    }
}
