import { Injectable } from '@angular/core';
import instrumentos from '../../shared/constants/instrumentos.json';
import { PermissionsService } from '../services/permissions.service';
import { CuentaService } from './cuenta.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UtilsInstrumentosService {
    private _arrayDetalleCotizaciones: string[] = [];
    private _tipoOperaciones!: any;

    constructor(
        private _permissionsService: PermissionsService,
        private _cuentaService: CuentaService,
        private _authService: AuthService
    ) {}

    getAllInstrumentos() {
        const instrumentosAll = instrumentos;
        return instrumentosAll;
    }

    tabsCotizaciones = (): { id: number; title: string; name: string }[] => {
        const tabs = [];
        const cuenta = this._cuentaService.getCuentaSeleccionada();
        instrumentos.forEach(item => {
            if (item.isTab) {
                if (
                    cuenta?.idNegocio !== 5 &&
                    !item.bci &&
                    this._permissionsService.checkPermissions('cotizaciones', item.idMenu)
                ) {
                    tabs.push({ id: item.panel, title: item.i18nName, name: item.name });
                }

                if (
                    cuenta?.idNegocio === 5 &&
                    item.bci &&
                    this._permissionsService.checkPermissions('cotizaciones', item.idMenu)
                ) {
                    tabs.push({ id: item.panel, title: item.i18nName, name: item.name });
                }
            }
        });
        return tabs;
    };

    operaciones = () => {
        const operaciones = [];
        instrumentos.forEach(item => {
            if (item?.panel) {
                operaciones.push({
                    descripcion: item.name,
                    panel: item.panel
                });
            }
        });
        return operaciones;
    };

    tiposOperar = () => {
        const tipos = [];
        instrumentos.forEach(item => {
            if (item.operable) {
                tipos.push(item.name);
            }
        });
        return tipos;
    };

    getTiposOperar = (panel: number) => instrumentos.find(instrumento => instrumento.panel === panel)?.tipoOperaciones;

    tipoOperaciones = () => {
        if (!this._tipoOperaciones) {
            let tipos = {};
            instrumentos.forEach(item => {
                if (item?.tipoOperaciones) {
                    tipos = Object.assign(tipos, {
                        [item.name]: item.tipoOperaciones
                    });
                }
            });
            this._tipoOperaciones = tipos;
        }
        return this._tipoOperaciones;
    };

    getTipoOperacionByName(name, idOperacion) {
        return this.tipoOperaciones()[name].filter(item => item.valor === idOperacion)[0]?.i18nDesc;
    }

    get nivelesACL() {
        return {
            Dolares: { 2: 'CI', 4: 'CI', 5: 'CI' },
            'Dolares Bolsa': { 2: 'CI', 4: 'CI', 5: 'CI' },
            Acciones: { 2: 'CI/24Hs/48Hs', 4: 'CI/24Hs/48Hs', 5: 'CI/24Hs/48Hs' },
            Bonos: { 2: 'CI/24Hs/48Hs', 4: 'CI/24Hs/48Hs', 5: 'CI/24Hs/48Hs' },
            Fondos: { 2: 'CI/24Hs/48Hs', 4: 'CI/24Hs/48Hs', 5: 'CI/24Hs/48Hs' },
            Cedears: { 2: 'CI/24Hs/48Hs', 4: 'CI/24Hs/48Hs', 5: 'CI/24Hs/48Hs' },
            Letras: { 2: 'CI/24Hs/48Hs', 4: 'CI/24Hs/48Hs', 5: 'CI/24Hs/48Hs' },
            Corporativos: { 2: 'CI/48Hs', 4: 'CI/24Hs/48Hs', 5: 'CI/24Hs/48Hs' },
            Opciones: { 2: '24Hs', 4: '24Hs', 5: '24Hs' },
            Futuros: { 2: 'CI', 4: 'CI', 5: 'CI' },
            Cauciones: { 2: 'CI/48Hs', 4: 'CI/24Hs/48HS', 5: 'CI/24Hs/48Hs' },
            Internacional: { 5: '48Hs' },
            'Acciones/ETF': { 5: '48Hs' }
        };
    }

    getNombreInstrumentoByPanel = (idPanel: number, returnObj?: boolean): string => {
        if (!idPanel) {
            return '';
        }
        let nombre = '';
        let objInstrumento;
        instrumentos.forEach(item => {
            if (item.panel === idPanel) {
                nombre = item.name;
                objInstrumento = item;
            }
        });
        if (returnObj) {
            return objInstrumento;
        } else {
            return nombre;
        }
    };

    tipoInvestmentIdeas = () => {
        let tipo = '';
        instrumentos.forEach(item => {
            if (item.name === 'Investment Ideas') {
                tipo = item.valor;
            }
        });
        return tipo;
    };

    verifyPermissions(instrumento: string, isName?: boolean): boolean {
        let result = false;
        instrumentos.forEach(item => {
            if (isName) {
                if (item.name?.toLowerCase() === instrumento?.toLowerCase()) {
                    result = this._permissionsService.checkPermissions('cotizaciones', item.idMenu);
                }
            } else if (item.valor?.toLowerCase() === instrumento?.toLowerCase()) {
                result = this._permissionsService.checkPermissions('cotizaciones', item.idMenu);
            }
        });
        return result;
    }
    verifyPermissionsOperar(instrumento: string, isName?: boolean): boolean {
        let result = false;
        instrumentos.forEach(item => {
            if (isName) {
                if (item.name?.toLowerCase() === instrumento?.toLowerCase()) {
                    result = this._permissionsService.checkPermissions('operar', item.idPermiso);
                }
            } else if (item.valor?.toLowerCase() === instrumento?.toLowerCase()) {
                result = this._permissionsService.checkPermissions('operar', item.idPermiso);
            }
        });
        return result;
    }
    verifyPermissionsByIdPermiso(idPermiso: string) {
        let result = false;
        instrumentos.forEach(item => {
            if (item.idPermiso === idPermiso) {
                result = this._permissionsService.checkPermissions('operar', item.idPermiso);
            }
        });
        return result;
    }
    getMenuDetalles(): string[] {
        if (!this._arrayDetalleCotizaciones.length) {
            this._arrayDetalleCotizaciones = instrumentos.reduce((acc, item) => {
                const detail = item.detalleUrl?.toLowerCase();
                if (!acc.includes('/app/cotizaciones')) {
                    acc.push('/app/cotizaciones');
                }
                if (!acc.includes(detail) && detail !== undefined) {
                    acc.push(detail);
                }
                return acc;
            }, []);
        }
        return this._arrayDetalleCotizaciones;
    }

    getPlazoDefaultByPanel(idPanel: number): number {
        const tipo = instrumentos.find(item => item.panel === idPanel)?.idPermiso;
        const plazo = this._authService.getPermissionByIdPermiso(tipo);
        return plazo >= 0 ? plazo : null;
    }

    precargaEnabled(instrumento: string): boolean {
        return (
            instrumentos.find(item => item.name?.toLowerCase() === instrumento?.toLowerCase())?.precargaEnabled ?? false
        );
    }
    checkPermissionsTransaccionalById(authPermision?) {
        let permisosTransaccional: any;
        if (authPermision) {
            permisosTransaccional = authPermision;
        } else {
            permisosTransaccional = this._authService.getCurrentUser();
        }
        return permisosTransaccional.Permisos.find(p => p.id === 'transaccional');
    }
    getOperarPermission(authPermision?) {
        let permisoOperar: any;
        if (authPermision) {
            permisoOperar = this.checkPermissionsTransaccionalById(authPermision);
        } else {
            permisoOperar = this.checkPermissionsTransaccionalById();
        }
        return permisoOperar.menu.find(p => p.id === 'operar').menu;
    }
    /** Valida si el panel coincide con el idNegocio */
    validatePanel(idPanel: number, isBCI: boolean): boolean {
        return !!instrumentos.find(i => i.panel === idPanel && i.bci === isBCI);
    }
}
