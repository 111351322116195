import { Injectable } from '@angular/core';
import { AlertType } from './../../shared/models/alerts-types.model';
import { AlertEvent, AlertEventConfig } from './../../shared/models/alert-event.model';
import { Observable, Subject } from 'rxjs';
import { EnvironmentService } from './environment.service';

/**
 * Servicio encargado de notificar las alertas dentro
 * de la aplicacion. Se puede emitir alertas de tipo
 * "success", "warning", "info" o "error" con las
 * funciones publicas y suscribirse a dichas alertas
 * con el get disponible "onNotification".
 */
@Injectable()
export class AlertsService {
    private _alertsCloseDelay!: number;
    private _onNotification: Subject<AlertEvent> = new Subject<AlertEvent>();

    /** Permite suscribirse a las alertas. */
    get onNotification(): Observable<AlertEvent> {
        return this._onNotification.asObservable();
    }

    constructor(private _envService: EnvironmentService) {
        this._alertsCloseDelay = this._envService.env.alertsCloseDelay;
    }

    private _notif(type: AlertType, message: string, config?: AlertEventConfig): void {
        this._onNotification.next({ message, type, config });
    }

    /** shortcut notif success */
    success(message: string, config?: AlertEventConfig): void {
        this._notif('success', message, config);
    }

    /** shortcut notif warning */
    warning(message: string, config?: AlertEventConfig): void {
        this._notif('warning', message, config);
    }

    /** shortcut notif info */
    info(message: string, config?: AlertEventConfig): void {
        this._notif('info', message, config);
    }

    /** shortcut notif error */
    error(message: string, config?: AlertEventConfig): void {
        this._notif('error', message, config);
    }
}
