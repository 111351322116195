import { Injectable } from '@angular/core';
import { Moneda } from '../../shared/models/moneda.model';
import { ParametrosNegocioService } from '../clients/parametros-negocio.service';
import { DecimalPipe } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class CurrencyFormatterService extends DecimalPipe {
    private _monedas: Moneda[] = [];

    constructor(private _parametrosNegocioService: ParametrosNegocioService) {
        super('en-US');
        this._parametrosNegocioService.monedas$().subscribe(res => {
            this._monedas = res;
        });
    }
    format(value: string | number, idMoneda?: string | number | null, format: string = '1.2-2'): any {
        let symbol = this._monedas.find((moneda: Moneda) => [moneda.idMoneda, moneda.Descripcion].includes(idMoneda))
            ?.Simbolo;
        symbol = symbol ? `${symbol} ` : '';

        if (value === 'symbol') {
            return symbol;
        }

        if (value === undefined || value === null || value === '' || value === 'Infinity') {
            return value;
        }
        let _value = value;

        if (!isNaN(Number(_value))) {
            _value = super
                .transform(value, format)
                .toString()
                .replace(/\,/g, '*');
        }

        const formattedValue = _value
            .toString()
            .replace(/\./g, ',')
            .replace(/\*/g, '.');

        if (idMoneda === '%') {
            return formattedValue + '%';
        } else {
            return symbol + formattedValue;
        }
    }
}
