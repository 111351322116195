import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ParametrosService } from './parametros.service';
import { EnvironmentService } from './environment.service';
import { ParametrosPersona } from 'src/app/shared/models/parametros-persona';

/**
 * Posibles Id para las secciones. En caso de haber una nueva ubicacion,
 * extender desde este tipo ya que el servicio es dinamico.
 */
export type SectionId = 'total' | 'available';

@Injectable()
export class HideMoneyService {
    private _isCustomerImpersonate!: boolean;
    private _sections: { [key: string]: boolean } = {
        total: false,
        available: false
    };

    constructor(
        private _authService: AuthService,
        private _parametrosService: ParametrosService,
        private _environmentService: EnvironmentService
    ) {
        this._isCustomerImpersonate = this._environmentService.env.isCustomerImpersonate;
        this._initSectionsStatus();
    }

    /**
     * Retorna true en caso de estar ocultada la seccion.
     */
    isSectionHidden(id: SectionId): boolean {
        return this._sections[id];
    }

    /**
     * Busca la seccion en base a su id y alterna el valor de ocultado.
     */
    toggleSection(id: SectionId): void {
        this._sections[id] = !this._sections[id];
        if (!this._isCustomerImpersonate) {
            this._saveSectionStatus({ Nombre: `${id}HideMoney`, Valor: String(this._sections[id]) });
        }
    }

    /**
     * Guarda el estado actual de las secciones en los parametros del usuario.
     */
    private _saveSectionStatus(payload: ParametrosPersona): void {
        if (this._isCustomerImpersonate) {
            return;
        }
        this._parametrosService.setParametroPersona(payload).subscribe((_: any) => {
            const params = this._authService.getCurrentUser().Parametros;
            Object.entries(this._sections).forEach(([key, value]) => {
                params[`${key}HideMoney`] = String(value);
            });
            this._authService.updateCurrentUser({ Parametros: params });
        });
    }

    /**
     * Setea los estados iniciales de las secciones en base a los parametros
     * del usuario obtenidos. Si no existe, el valor se mostrará.
     */
    private _initSectionsStatus(): void {
        if (this._isCustomerImpersonate) {
            return;
        }
        const userParams = this._authService.getCurrentUser()?.Parametros;
        if (!userParams) {
            return;
        }
        Object.entries(this._sections).forEach(([key, value]) => {
            this._sections[key] = userParams[`${key}HideMoney`] && userParams[`${key}HideMoney`] === 'true';
        });
    }
}
