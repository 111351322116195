import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { CuentaService } from '../clients/cuenta.service';

@Injectable()
export class CuentaResolver implements Resolve<void> {
    constructor(private _cuentaService: CuentaService) {}

    resolve(): Observable<void> | Promise<void> | void {
        this._cuentaService.initCuentas();
    }
}
