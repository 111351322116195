import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ModalV2TemplatesConfig {
    template: string;
    title: string;
    context: any;
}

@Injectable()
export class ModalV2TemplatesService {
    private _config: BehaviorSubject<ModalV2TemplatesConfig>;
    private _onClose: BehaviorSubject<boolean>;

    get config(): Observable<ModalV2TemplatesConfig> {
        return this._config.asObservable();
    }

    constructor() {
        this._config = new BehaviorSubject<ModalV2TemplatesConfig>(null);
        this._onClose = new BehaviorSubject<boolean>(false);
    }

    openTemplate(config: ModalV2TemplatesConfig): Observable<boolean> {
        this._config.next(config);
        return this._onClose.asObservable();
    }

    closeTemplate(): void {
        this._onClose.next(true);
    }
}
