import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

// guards
import { BaseExceptionHandler } from './exceptions-handler/base.exception-handler';
import { LoggedLayoutGuard } from './guards/logged-layout.guard';
import { UnloggedLayoutGuard } from './guards/unlogged-layout.guard';
import { CotizacionesGuard } from './guards/cotizaciones.guard';

// interceptors
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';

// services
import { BlzEnvironment, EnvironmentService } from './services/environment.service';
import { AlertsService } from './services/alerts.service';
import { DeviceService } from './services/device.service';
import { AuthService, AuthServiceFactory } from './services/auth.service';
import { ParametrosService } from './services/parametros.service';
import { FaqService } from './services/faq.service';
import { LayoutService } from './services/layout.service';
import { LoadingService } from './services/loading.service';
import { LoggerService } from './services/logger.service';
import { MiConfiguracionService } from './services/mi-configuracion.service';
import { NotificacionesService } from './services/notificaciones.service';
import { SortService } from './services/sort.service';
import { ThemeService } from './services/theme.service';
import { WebSocketService } from './services/web-socket.service';
import { WidgetsService } from './services/widgets.service';
import { PermissionsService } from './services/permissions.service';
import { CurrencyFormatterService } from './services/currency-formatter.service';
import { CotizacionesConfigService } from './services/cotizaciones-config.service';
import { InstrumentTypeService } from './services/instrument-type.service';
import { ModalV2Service } from './services/modal-v2.service';
import { HideMoneyService } from './services/hide-money.service';
import { ShareTextService } from './services/share-text.service';
import { DeeplinkService } from './services/deeplink.service';

// resolver
import { NotificationsResolver } from './resolvers/notifications-resolver.service';
import { CuentaResolver } from './resolvers/cuenta-resolver.service';
import { UtilsInstrumentosService } from './clients/utils-instrumentos.service';
import { ParametrosNegocioResolver } from './resolvers/parametros-negocio-resolver.service';
import { BannersResolver } from './resolvers/banners-resolver.service';
import { InstrumentosResolver } from './resolvers/instrumentos-resolver.service';

// clients
import { PriceAlertsService } from './clients/price-alerts.service';
import { BancoService } from './clients/banco.service';
import { BannersService } from './clients/banners.service';
import { BonosService } from './clients/bono.service';
import { CarteraService } from './clients/cartera.service';
import { CaucionService } from './clients/caucion.service';
import { ConfiguracionService } from './clients/configuracion.service';
import { CotizacionesClientService } from './clients/cotizaciones-client.service';
import { CuentaService } from './clients/cuenta.service';
import { GeneralService } from './clients/general.service';
import { InstrumentosService } from './clients/instrumentos.service';
import { ParametrosNegocioService } from './clients/parametros-negocio.service';
import { MovimientoService } from './clients/movimiento.service';
import { OperarService } from './clients/operar.service';
import { OrdenService } from './clients/orden.service';
import { SubCarteraService } from './clients/subcartera.service';
import { TransferenciasService } from './clients/transferencias.service';
import { WatchlistService } from './clients/watchlist.service';
import { MenuDrawerService } from './services/menu-drawer.service';
import { DetallesGuard } from './guards/detalles.guard';
import { ParametrosResolver } from './resolvers/parametros-resolver.services';
import { ViewService } from './services/view.service';
import { ShareTickerService } from './services/share-ticker.service';
import { ModalV2TemplatesService } from './services/modal-v2-templates.service';
import { UtilsTranslateService } from './services/utils-translate.service';
import { DescargaReportesService } from './clients/descarga-reportes.service';
import { BzServicesModule } from '@balanz/services';
import { SocialService } from './clients/social.service';
import { TickersService } from './services/ticker.services';
import { CorporativosService } from './clients/corporativos.service';

@NgModule({
    imports: [
        BzServicesModule.forRoot({
            appName: 'plataforma-clientes-v2',
            needEncrypt: environment.production,
            encrypterSecret: environment.encrypterSecret
        })
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                NotificationsResolver,
                ParametrosNegocioResolver,
                ParametrosResolver,
                CuentaResolver,
                BannersResolver,
                AlertsService,
                PriceAlertsService,
                ThemeService,
                PermissionsService,
                WebSocketService,
                WidgetsService,
                BancoService,
                NotificacionesService,
                SortService,
                WatchlistService,
                MiConfiguracionService,
                TransferenciasService,
                OrdenService,
                OperarService,
                SubCarteraService,
                ParametrosNegocioService,
                MovimientoService,
                LoadingService,
                LayoutService,
                BannersService,
                GeneralService,
                LoggerService,
                InstrumentosService,
                FaqService,
                BonosService,
                CuentaService,
                DeviceService,
                AuthService,
                ParametrosService,
                CaucionService,
                CotizacionesClientService,
                ConfiguracionService,
                CarteraService,
                LoggedLayoutGuard,
                UnloggedLayoutGuard,
                DetallesGuard,
                EnvironmentService,
                BlzEnvironment,
                MenuDrawerService,
                UtilsInstrumentosService,
                CurrencyFormatterService,
                InstrumentosResolver,
                CotizacionesConfigService,
                InstrumentTypeService,
                ModalV2Service,
                HideMoneyService,
                ShareTextService,
                CotizacionesGuard,
                ViewService,
                ShareTickerService,
                DeeplinkService,
                UtilsTranslateService,
                ModalV2TemplatesService,
                SocialService,
                DescargaReportesService,
                TickersService,
                CorporativosService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: TokenInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ErrorInterceptor,
                    multi: true
                },
                {
                    provide: ErrorHandler,
                    useClass: BaseExceptionHandler
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: AuthServiceFactory,
                    multi: true,
                    deps: [AuthService]
                },
                {
                    provide: BlzEnvironment,
                    useValue: environment
                }
            ]
        };
    }
}
