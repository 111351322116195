export type DeviceType = 'mobile' | 'tablet' | 'small-desktop' | 'desktop';

export const getDeviceByWidth = (width: number | string): DeviceType => {
    if (width < 720) {
        return 'mobile';
    } else if (width < 1200) {
        return 'tablet';
    } else if (width < 1440) {
        return 'small-desktop';
    } else {
        return 'desktop';
    }
};
