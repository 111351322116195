import { Injectable } from '@angular/core';

@Injectable()
export class InstrumentTypeService {
    fromPanel(panel: number, tipo: string = null): string {
        panel = Number(panel);
        switch (panel) {
            case 4:
                return 'acciones';
            case 6:
                return 'bonos';
            case 7:
                return 'cedears';
            case 15:
                return 'letras';
            case 16:
                return 'corporativos';
            case 8:
                return 'opciones';
            case 10:
                return 'fondoscomunesdeinversion';
            case 9:
            case 31:
            case 33:
                return 'futuros';
            case 34:
                return 'cauciones';
            case 37:
                return 'real-estate';
            case 38:
            case 39:
                return 'investmentideas';
            case 19:
                return 'internacional';
            case 60:
                return 'acciones/etf';
            case 64:
                return 'treasuries';
            default:
                return tipo ? tipo.toLowerCase() : 'unknown';
        }
    }
}
