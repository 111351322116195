import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, interval, EMPTY, Subscription, ReplaySubject, of } from 'rxjs';
import { delay, finalize, map, retryWhen } from 'rxjs/operators';
import { Cuenta } from '../../shared/models/cuenta';
import { CuentaBancariaBalanz } from '../../shared/models/cuenta-bancaria-balanz.model';
import { AuthService } from '../services/auth.service';
import { BzStorageService } from '@balanz/services';
import moment from 'moment';
import { EnvironmentService } from '../services/environment.service';
import { EstadoCuenta, EstadoCuentaLastParams, EstadoCuentaParams } from '../../shared/models/estado-cuenta';
import { DetalleDisponible } from '../../shared/models/disponible';
import { CuentasVinculadasResponse } from '../../shared/models/cuentas-vinculadas-response.model';
import { ViewService } from '../services/view.service';
@Injectable()
export class CuentaService {
    private _refreshTime = this._envService.env.timeRefreshDataMinutes * 60000;
    private _timeDelay = this._envService.env.timeDelaySeconds;
    private _basePath = this._envService.env.BaseUrlService;
    private _previousAccountSelected: Cuenta | null = null;
    private _currentAccountSelected: BehaviorSubject<Cuenta>;
    private estadoCuentaSubject: BehaviorSubject<any>;

    private _onEstadoCuentaChange = new ReplaySubject<EstadoCuenta>(1);
    private _onViewChange!: Subscription;
    private _estadoCuentaSub!: Subscription;
    private _estadoCuentaParams: EstadoCuentaParams = { Fecha: moment().format('YYYYMMDD'), ta: '1', idMoneda: '1' };
    private _estadoCuentaLastParams: EstadoCuentaLastParams = {
        Fecha: moment().format('YYYYMMDD'),
        ta: '1',
        idMoneda: '1',
        idCuenta: 1
    };
    private _estadoCuentaLoading = new BehaviorSubject<boolean>(false);

    private _onCuentas = new ReplaySubject<Cuenta[]>(1);
    private _cuentasSub: Subscription = null;

    private _onDetalleDisponibleChange = new ReplaySubject<DetalleDisponible>(1);
    private _detalleDisponibleSub!: Subscription;
    private _fechaDetalle: string;
    private _debounceEstadoCuenta = false;
    private _debounceObsEstadoCuenta;
    private _estadoIntervalSub: Subscription;

    get estadoCuentaLoading$(): Observable<boolean> {
        return this._estadoCuentaLoading.asObservable();
    }

    get tieneTelefono(): boolean {
        return !!this.getMovil();
    }

    get esCuentaCera(): boolean {
        return this.getCuentaSeleccionada().Descripcion.startsWith('CERA -');
    }

    get lastParams(): EstadoCuentaLastParams {
        return this._estadoCuentaLastParams;
    }

    constructor(
        private http: HttpClient,
        private storage: BzStorageService,
        private authService: AuthService,
        private _envService: EnvironmentService,
        private _viewService: ViewService
    ) {
        this.storage.remove('status_request_cuentas');
        if (this._envService.env.isCustomerImpersonate) {
            const currentAccount = this.storage.get('asesores-v2_currentAccount', false, true);
            if (currentAccount) {
                this.storage.set('currentAccount', currentAccount);
            }
        } else {
            /* MIGRACION DEL STORAGE, BORRAR AL TERMINAR */
            const currentAccount = this.storage.get('currentAccount', false, true);
            if (currentAccount) {
                this.storage.set('currentAccount', currentAccount);
                this.storage.remove('currentAccount', false, true);
            }
            /* MIGRACION DEL STORAGE, BORRAR AL TERMINAR */
        }
        this._currentAccountSelected = new BehaviorSubject<Cuenta>(this.storage.get('currentAccount'));
        this.estadoCuentaSubject = new BehaviorSubject({});
    }

    /**
     * Retorna la cuenta seleccionada actualmente.
     */
    getCuentaSeleccionada(): Cuenta {
        return this._currentAccountSelected.value;
    }

    /**
     * Retorna la cuenta seleccionada previamente.
     */
    getPreviousAccountSelected(): Cuenta | null {
        return this._previousAccountSelected;
    }

    /**
     * Actualiza la cuenta seleccionada e informa el cambio.
     */
    setCuentaSeleccionada(cuenta: Cuenta): void {
        this.updateSelectedAccount(cuenta);
    }

    getCuentasVinculadas(cuenta): Observable<CuentasVinculadasResponse> {
        return this.http.get<any>(`${this._basePath}/cuentasbancariasvinculadas/${cuenta}`);
    }

    getCuentas(reload: boolean = false, idPersona?): Observable<Cuenta[]> {
        if (reload) {
            this._refreshCuentas(idPersona >= 0 ? idPersona : undefined);
        }
        return this._onCuentas.asObservable();
    }

    getDatosCuenta(cuenta): Observable<any> {
        const url = this._basePath + '/datoscuenta/' + cuenta;
        return this.http.get<any>(url);
    }

    estadoCuenta$(): Observable<any> {
        return this.estadoCuentaSubject.asObservable();
    }

    getBoletos(fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(1, 'weeks')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/boletos/${cuenta.idCuenta}`, {
                params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta }
            })
            .pipe(map((item: any) => item.boletos));
    }

    getCuentaCorriente(fechaDesde?, fechaHasta?, liquidacion?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(1, 'weeks')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        liquidacion = liquidacion ? liquidacion : 0;
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/cuentascorrientes/${cuenta.idCuenta}`, {
                params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta, Liquidacion: liquidacion }
            })
            .pipe(map((item: any) => item.ctacorriente));
    }

    getTieneOrdenes(): Observable<any> {
        const url = this._basePath + '/existenOrdenes/' + this.getCuentaSeleccionada()?.idCuenta;
        return this.http.get<any>(url);
    }

    getHistoricoOrdenes(fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(1, 'weeks')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/reportehistoricoordenes/${cuenta.idCuenta}`, {
                params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta }
            })
            .pipe(map((item: any) => item.ordenes));
    }

    getHistoricoOrdenesPrecargas(fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(1, 'weeks')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/reportehistoricoprecargas/${cuenta.idCuenta}`, {
                params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta }
            })
            .pipe(map((item: any) => item.ordenes));
    }

    getDetallePrecarga(idPrecarga): Observable<any> {
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/precargar/detalle/${cuenta.idCuenta}/${idPrecarga}`)
            .pipe(map(resp => resp['detalle']));
    }
    // Para actualizar los parámetros con los que se pide el estado de cuenta
    updateEstadoCuentaParams(fecha?, ta?: number | string, idMoneda?: number | string) {
        fecha = fecha
            ? moment(fecha).format('YYYYMMDD')
            : this._estadoCuentaParams.Fecha
            ? this._estadoCuentaParams.Fecha
            : moment().format('YYYYMMDD');
        ta = ta ? ta.toString() : '1';
        idMoneda = idMoneda ? idMoneda.toString() : this._estadoCuentaParams.idMoneda || '1';
        this._estadoCuentaLastParams = { ...this._estadoCuentaParams, idCuenta: this._estadoCuentaLastParams.idCuenta };
        this._estadoCuentaParams = { Fecha: fecha, ta, idMoneda };
    }

    // Recibe un parámetro reload en true para forzar traer valor nuevo en caso de ser necesario
    getEstado(reload?: boolean, skipDebounce?: boolean): Observable<EstadoCuenta> {
        if (reload) {
            this._refreshEstadoCuenta(false, skipDebounce);
        }
        return this._onEstadoCuentaChange.asObservable();
    }

    initIntervalEstadoCuenta(): void {
        this._refreshEstadoCuenta(true);
    }

    clearIntervalEstadoCuenta(): void {
        if (this._estadoIntervalSub) {
            this._estadoIntervalSub.unsubscribe();
        }
    }

    getDetalle(idCuenta): Observable<any> {
        return this.http.get(`${this._basePath}/detallecuenta/${idCuenta}`);
    }

    getDetalleDisponibleCuenta(cuenta, params): Observable<any> {
        const fecha = moment().format('YYYYMMDD');
        if (!cuenta) {
            return EMPTY;
        }

        return this.http.get(`${this._basePath}/detalledisponible/${cuenta}`, { params: { ...params, Fecha: fecha } });
    }

    getDetalleDisponible(fecha?, reload?: boolean): Observable<DetalleDisponible> {
        if (reload) {
            this._refreshDetalleDisponible();
        }
        this._fechaDetalle = fecha;
        return this._onDetalleDisponibleChange.asObservable();
    }

    createCuentaBancaria(cuenta): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.post(`${this._basePath}/cbu/agregar`, { ...cuenta, idPersona: auth.idPersona });
    }

    createCuentaBancariaWithIdCuenta(idCuenta, cuenta): Observable<any> {
        if (cuenta.hasOwnProperty('idPersona')) {
            delete cuenta.idPersona;
        }
        return this.http.post(`${this._basePath}/cbu/agregar`, { ...cuenta, idCuenta: idCuenta });
    }

    updateCuentaBancaria(idCuenta, cuenta): Observable<any> {
        return this.http.put(`${this._basePath}/cbu/${idCuenta}`, { ...cuenta });
    }

    removeCuentaBancaria(idCuenta): Observable<any> {
        return this.http.delete(`${this._basePath}/cbu/${idCuenta}`);
    }

    /**
     * Informa los cambios de cuenta seleccionada.
     */
    changeCuentaSeleccionada(): Observable<Cuenta> {
        return this._currentAccountSelected.asObservable();
    }

    getCuentasBancarias(): Observable<CuentaBancariaBalanz[]> {
        return this.http.get(`${this._basePath}/cuentasbancariasbalanz`).pipe(map((res: any) => res.cuentas));
    }

    getFondos(): Observable<any> {
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/fondos`, { params: { idCuenta: cuenta.idCuenta.toString() } })
            .pipe(map((res: any) => res.fondos));
    }

    changeCuentaDefault(idCuenta): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.post(`${this._basePath}/cuentas/${auth.idPersona}/default`, { idCuenta: idCuenta.toString() });
    }

    /**
     * Setea la nueva cuenta seleccionada guardando la previa e informando
     * el cambio.
     *
     * @param account Nueva cuenta seleccionada.
     */
    private updateSelectedAccount(account: Cuenta): void {
        this.storage.set('currentAccount', account);
        this._previousAccountSelected = this.getCuentaSeleccionada();
        this._currentAccountSelected.next(account);
    }

    private _refreshEstadoCuenta(isFirstTime: boolean = false, skipDebounce?: boolean): void {
        if (
            !this._debounceEstadoCuenta ||
            skipDebounce ||
            isFirstTime ||
            this._estadoCuentaParams.Fecha !== this._estadoCuentaLastParams.Fecha ||
            this._estadoCuentaParams.ta !== this._estadoCuentaLastParams.ta ||
            this._estadoCuentaParams.idMoneda !== this._estadoCuentaLastParams.idMoneda ||
            this.getCuentaSeleccionada()?.idCuenta !== this._estadoCuentaLastParams.idCuenta
        ) {
            if (this.getCuentaSeleccionada()) {
                this._estadoCuentaLastParams.idCuenta = this.getCuentaSeleccionada()?.idCuenta;
            }

            this._debounceEstadoCuenta = true;
            this._debounceObsEstadoCuenta = of(false)
                .pipe(delay(this._timeDelay * 1000))
                .subscribe((res: boolean) => {
                    this._debounceEstadoCuenta = res;
                    this._debounceObsEstadoCuenta.unsubscribe();
                });

            if (this._estadoCuentaSub && !this._estadoCuentaSub.closed) {
                this._estadoCuentaSub.unsubscribe();
            }
            const cuenta = this.getCuentaSeleccionada();
            if (isFirstTime || this._viewService.viewActive) {
                this._estadoCuentaLoading.next(true);
                if (cuenta && cuenta.idCuenta) {
                    this.getEstadoCuenta(cuenta, isFirstTime);
                }
            }

            this._onViewChange?.unsubscribe();
            this._onViewChange = this._viewService.onViewChange.subscribe((value: boolean) => {
                if (!value && this._estadoCuentaSub?.closed) {
                    this._estadoCuentaSub.unsubscribe();
                }
            });
        }
    }
    getOrdenesDiarias(cuenta) {
        return this.http.get(`${this._basePath}/ordenesdiarias/${cuenta.idCuenta}`);
    }
    private getEstadoCuenta(cuenta, isFirstTime: boolean = false) {
        this._estadoCuentaSub = this.http
            .get(`${this._basePath}/estadodecuenta/${cuenta.idCuenta}`, { params: { ...this._estadoCuentaParams } })
            .pipe(
                retryWhen(errors => errors.pipe(delay(this._refreshTime))),
                finalize(() => {
                    this._estadoCuentaLoading.next(false);
                }),
                map((item: any) => {
                    item?.tenencia.forEach(element => {
                        element.Tipo = element.Tipo === 'Renta Fija' ? 'Bonos' : element.Tipo;
                    });

                    item?.tenenciaAgrupada.forEach(element => {
                        element.Tipo = element.Tipo === 'Renta Fija' ? 'Bonos' : element.Tipo;
                    });

                    return item;
                })
            )
            .subscribe((res: EstadoCuenta) => {
                this._onEstadoCuentaChange.next(res);
                this._estadoCuentaSub.unsubscribe();
                if (res?.timerRefresh[0]?.TimerRefresh !== this._refreshTime || isFirstTime) {
                    this._refreshTime = res?.timerRefresh[0]?.TimerRefresh || this._refreshTime;
                    this._setIntervalEstadoCuenta(this._refreshTime);
                }
            });
    }

    private _setIntervalEstadoCuenta(refreshTime: number): void {
        this.clearIntervalEstadoCuenta();
        this._estadoIntervalSub = interval(refreshTime).subscribe(() => {
            this._refreshEstadoCuenta();
        });
    }

    private _refreshDetalleDisponible(): void {
        if (this._detalleDisponibleSub && !this._detalleDisponibleSub.closed) {
            this._detalleDisponibleSub.unsubscribe();
        }
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta) {
            return;
        }
        const fecha = moment(this._fechaDetalle).format('YYYYMMDD') || moment().format('YYYYMMDD');
        this._detalleDisponibleSub = this.http
            .get(`${this._basePath}/detalledisponible/${cuenta.idCuenta}`, { params: { Fecha: fecha } })
            .pipe(retryWhen(errors => errors.pipe(delay(this._refreshTime))))
            .subscribe((res: DetalleDisponible) => {
                this._onDetalleDisponibleChange.next(res);
                this._detalleDisponibleSub.unsubscribe();
            });
    }

    private _refreshCuentas(idPersona?): void {
        if (this._cuentasSub && !this._cuentasSub.closed) {
            this._cuentasSub.unsubscribe();
        }
        const auth = this.authService.getCurrentUser();
        const id = idPersona || auth.idPersona;

        this._cuentasSub = this.http.get(`${this._basePath}/cuentas/${id}`).subscribe(
            (item: any) => {
                const cuentas: Cuenta[] = item.cuentas;
                const cuentaSeleccionada = this.getCuentaSeleccionada();
                if (!cuentaSeleccionada) {
                    this.updateSelectedAccount(cuentas[0]);
                }
                this._onCuentas.next(cuentas);
                this._cuentasSub.unsubscribe();
            },
            _ => this.storage.set('status_request_cuentas', 'ERROR')
        );
    }

    initCuentas() {
        // Check si viene de asesores. Si esta embebido saca cuentas del storage
        if (this._envService.env.isCustomerImpersonate) {
            this.updateSelectedAccount(this.storage.get('currentAccount'));
            this._onCuentas.next([this.storage.get('currentAccount')]);
        } else {
            const cuentas: Cuenta[] = this.authService.getCurrentUser().cuentas;
            /** Chequeo si vienen cuentas del servicio. En caso de no tener las pido al servicio viejo.
      esto se puede dar si intenta loggear y no habia aceptado terminos y condiciones **/
            if (cuentas && cuentas?.length > 0) {
                const cuentaSeleccionada = this.getCuentaSeleccionada();
                if (!cuentaSeleccionada) {
                    this.updateSelectedAccount(cuentas[0]);
                }
                this._onCuentas.next(cuentas);
            } else {
                this._refreshCuentas();
            }
        }
    }

    descargarResumenCuenta(fecha: string, idMoneda?: number): Observable<Blob> {
        const cuenta = this.getCuentaSeleccionada();
        const params = {
            fecha,
            idMoneda
        };
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        // Si la cuenta es BCI no se envia el param idMoneda.
        if (cuenta.idNegocio === 5) {
            delete params.idMoneda;
        }

        return this.http.get(`${this._basePath}/descargar/6/${cuenta.idCuenta}`, {
            headers: new HttpHeaders().set('Accept', 'application/pdf'),
            responseType: 'blob',
            params
        });
    }
    verificacionTyC() {
        return this.http.get(`${this._basePath}//terminos/verificartyc/balanzcambio`);
    }
    aceptarTyC(payload) {
        return this.http.post(`${this._basePath}/terminos/aceptartyc`, payload);
    }
    abrirCuenta() {
        return this.http.post(`${this._basePath}//balanzcambio/abrircuenta`, null);
    }

    getResumenEstadodeCuenta(): Observable<any> {
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta?.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/estadoDeCuenta/ObtenerResumenesMensual/${cuenta.idCuenta}`)
            .pipe(map(items => items['Resumenes']));
    }

    descargarResumenEstadodeCuenta(idResumen: number): Observable<any> {
        const cuenta = this.getCuentaSeleccionada();
        if (!cuenta?.idCuenta) {
            return EMPTY;
        }
        return this.http.get(
            `${this._basePath}/estadoDeCuenta/DescargarResumenMensual/${cuenta.idCuenta}/${idResumen}`,
            {
                headers: new HttpHeaders().set('Accept', 'application/pdf'),
                responseType: 'blob'
            }
        );
    }

    getMovil(): string {
        return this.authService.getCurrentUser()?.TelefonoMovil;
    }
}
