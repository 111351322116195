import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';
import { CuentaService } from './cuenta.service';
import { PermissionsService } from '../services/permissions.service';
import { CotizacionesClientService } from './cotizaciones-client.service';

export interface Instrumento {
    Cantidad: number;
    Descripcion: string;
    DisplayTicker: string;
    EnWatchlist: number;
    Mercados: string;
    Ticker: string;
    Tipo: string;
    idFondo: number;
    idMoneda: number;
    orden: number;
    panel: number;
    /* variables auxiliares utilizadas por el search-bar, no provienen del back. */
    selected?: boolean;
    matchScore?: number;
}

@Injectable()
export class InstrumentosService {
    private _basePath = this._envService.env.BaseUrlService;
    private _instrumentos: Instrumento[] = [];
    private _instrumentosInternacional: Instrumento[] = [];
    private cuentas$: Subscription;

    /**
     * 1- Compra | 2- Venta | 20- Caución Colocadora | 21- Caución Tomadora
     */
    private _isEspecies = [1,2,20,21];
    /**
     * 8- Suscripción desde cuenta Balanz | 9 - Suscripción desde banco | 10- Rescate a cuenta Balanz
     * 11- Rescate a Banco | 12- Cambio de fondo | 18- Suscripción ACDI | 19- Rescate ACDI
     */
    private _isFondo = [8,9,10,11,12,18,19];
    /**
     * 13- Licitación | 14- Renovación
     */
    private _isLicitaciones = [13,14];
    /** 6 Transferencia */
    private _isTransferenciaDinero = [6];
    /** 16- Compra Dólares | 17- Venta Dólares */
    private _isDolarOficial = [16,17];
    /** 22- Compra Dólar Bolsa | 23- Venta Dólar Bolsa */
    private _sDolarMep = [22,23];
    /** 24- Transferencia de Títulos | 32- Recepción de Títulos */
    private _isTransferenciaTitulos = [24,32];
    /** 25- Aporte con Títulos desde Comitente | 26- Aporte por transferencia Bancaria
     *  27- Rescate desde Externo a Comitente | 28- Rescate desde Externo a Banco
     */
    private _isCrowdium = [25,26,27,28];
    /** 29- Compra Pack | 30- Venta Pack */
    private _isPacks = [29,30];
    /** 31- Conversión */
    private _isConversionEspecies = [31];

    constructor(
        private http: HttpClient,
        private _envService: EnvironmentService,
        private _cuentaService: CuentaService,
        private _cotizacionesClientService: CotizacionesClientService,
        private _permissionsService: PermissionsService
    ) {}

    /**
     * Obtiene los instrumentos del panel 99 y los filtra por permisos, o los del panel 97 si es cuenta internacional.
     */
    currentInstrumentos(traceableOnly: boolean = false): Instrumento[] {
        if (this._cuentaService.getCuentaSeleccionada()?.idNegocio === 5) {
            return this._instrumentosInternacional;
        } else {
            return this._instrumentos.filter(
                instrumento =>
                    (!traceableOnly || ![10, 37, 38, 39].includes(instrumento.panel)) &&
                    this._permissionsService.checkPermissions(
                        'cotizaciones',
                        this._cotizacionesClientService.getTipoInstrumentoByPanel(instrumento.panel)[0]?.idMenu
                    )
            );
        }
    }

    getCotizacionHistorico(ticker, plazo): Observable<any> {
        const url = `${this._basePath}/cotizacionhistorico?ticker=${ticker}&plazo=${plazo}`;
        return this.http.get<any>(url);
    }

    getCotizacionInstrumento(ticker): Observable<any> {
        return this.http.get<any>(`${this._basePath}/cotizacioninstrumento?ticker=${ticker}`);
    }

    getCotizacionIntradiario(ticker, plazo, group = 0): Observable<any> {
        const url = `${this._basePath}/cotizacionintradiario?ticker=${ticker}&plazo=${plazo}&detalle=1&agrupado=${group}`;
        return this.http.get<any>(url);
    }

    chargeInstrumentos(): void {
        this._refreshInstrumentos();
    }

    private _refreshInstrumentos(): void {
        if (this.cuentas$) {
            this.cuentas$.unsubscribe();
        }
        this.cuentas$ = this._cuentaService.getCuentas().subscribe(cuentas => {
            const int = cuentas.some(item => item.idNegocio === 5);
            const loc = cuentas.some(item => item.idNegocio === 1);
            if (loc) {
                const sub: Subscription = this.http
                    .get(`${this._basePath}/especies`, {
                        params: { panel: 99, avoidAuthRedirect: true }
                    })
                    .subscribe((data: any) => {
                        this._instrumentos = data.especies;
                        sub.unsubscribe();
                    });
            }
            if (int) {
                const sub2: Subscription = this.http
                    .get(`${this._basePath}/especies`, {
                        params: { panel: 97, avoidAuthRedirect: true }
                    })
                    .subscribe((data: any) => {
                        this._instrumentosInternacional = data.especies;
                        sub2.unsubscribe();
                    });
            }
        });
    }

    isFondo = (idTipoOperacion:number) => this._isFondo.includes(idTipoOperacion);
    isLicitacion = (idTipoOperacion:number) => this._isLicitaciones.includes(idTipoOperacion);
}
