import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ParametrosNegocioService } from '../clients/parametros-negocio.service';

@Injectable()
export class ParametrosNegocioResolver implements Resolve<void> {
    constructor(private _parametrosNegocioService: ParametrosNegocioService) {}

    resolve(): Observable<void> | Promise<void> | void {
        this._parametrosNegocioService.loadBussinessParams();
    }
}
