import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from './auth.service';
import { CuentasBancarias } from '../../shared/models/cuentasBancarias';
import { DatosPersonales } from '../../shared/models/datosPersonales';
import { Dispositivos } from '../../shared/models/dispositivos';
import { Tyc } from '../../shared/models/tyc';

@Injectable()
export class MiConfiguracionService {
    private _cuentaBancarias: ReplaySubject<CuentasBancarias[]> = new ReplaySubject(1);
    private _datosPersonales: ReplaySubject<DatosPersonales[]> = new ReplaySubject(1);
    private _dispositivos: ReplaySubject<Dispositivos[]> = new ReplaySubject(1);
    private _servicios: ReplaySubject<any> = new ReplaySubject(1);
    private _tyc: ReplaySubject<Tyc[]> = new ReplaySubject(1);
    private _detalle;

    constructor(private authService: AuthService) {}

    async InitDetalle(): Promise<void> {
        this._detalle = await this.authService.getDetalle().toPromise();
        this.setDetalleCuentaBancaria(this._detalle.cuentasbancarias);
        this.setDetalleDatosPersonales(this._detalle.datospersonales);
        this.setDetalleDispositivos(this._detalle.dispositivos);
        this.setDetalleServicios(this._detalle.servicios);
        this.setDetalleTyC(this._detalle.tyc);
    }

    setDetalleCuentaBancaria(cuenta): void {
        this._cuentaBancarias.next(cuenta);
    }

    listenDetalleCuentaBancaria(): Observable<CuentasBancarias[]> {
        return this._cuentaBancarias.asObservable();
    }

    setDetalleDatosPersonales(datos): void {
        const dataAlmacenada = this.authService.getCurrentUser();
        if (dataAlmacenada) {
            dataAlmacenada.TelefonoMovil = datos[0].TelefonoMovil;
            dataAlmacenada.tieneC4D = datos[0].tieneC4D;
            this.authService.updateCurrentUser(dataAlmacenada);
            this._datosPersonales.next(datos);
        }
    }

    listenDetalleDatosPersonales(): Observable<DatosPersonales[]> {
        return this._datosPersonales.asObservable();
    }

    setDetalleDispositivos(dispositivos): void {
        this._dispositivos.next(dispositivos);
    }

    listenDetalleDispositivos(): Observable<Dispositivos[]> {
        return this._dispositivos.asObservable();
    }

    setDetalleServicios(servicios): void {
        this._servicios.next(servicios);
    }

    listenDetalleServicios(): Observable<any[]> {
        return this._servicios.asObservable();
    }

    setDetalleTyC(tyc): void {
        this._tyc.next(tyc);
    }

    listenDetalleTyC(): Observable<Tyc[]> {
        return this._tyc.asObservable();
    }
}
