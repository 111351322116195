import { Injectable } from '@angular/core';
import { Observable, EMPTY, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CuentaService } from './cuenta.service';
import moment from 'moment';
import { AuthService } from '../services/auth.service';
import { switchMap, map } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { BzStorageService } from '@balanz/services';

@Injectable()
export class GeneralService {
    private _basePath = this._envService.env.BaseUrlService;
    constructor(
        private http: HttpClient,
        private cuentaService: CuentaService,
        private authService: AuthService,
        private _envService: EnvironmentService,
        private _storageService: BzStorageService
    ) {}

    getResultados(fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(2, 'years')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http.get(`${this._basePath}/realizado/${cuenta.idCuenta}`, {
            params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta }
        });
    }

    getResultadosLotes(fechaDesde?, fechaHasta?, skipEjecutar = false): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(2, 'years')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http.get(`${this._basePath}/realizado/lotes/${cuenta.idCuenta}`, {
            params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta, ejecutar: skipEjecutar ? 0 : 1 }
        });
    }

    aceptarTerminosyCondiciones(idTerminoYCondicion) {
        const auth = this.authService.getCurrentUser();
        const url = this._basePath + '/terminos/aceptartyc';
        return this.http.post(url, { idPersona: auth.idPersona, idTerminoYCondicion });
    }

    getAllTestsInversor(): Observable<any> {
        return this.http.get(`${this._basePath}/testinversor`);
    }

    getDetalleTestInversor(): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.get(`${this._basePath}/testinversor`).pipe(
            switchMap((data: any) => {
                const resultadoTest = data.personal.find(
                    test => parseInt(test.idPersona, 10) === parseInt(auth.idPersona, 10)
                );
                // Si ya realizo el test retorna obs de resultados
                if (resultadoTest && resultadoTest.idPerfil !== 0) {
                    return this.http.get(`${this._basePath}/testinversor/persona?idPersona=${auth.idPersona}`).pipe(
                        map(data2 => ({
                            status: 'finished',
                            data2
                        }))
                    );
                }
                return of({ status: 'pending', data: [] });
            })
        );
    }

    getDetalleTestInversorByIdCuenta(idCuenta): Observable<any> {
        return this.http.get(`${this._basePath}/testinversor`).pipe(
            switchMap((data: any) => {
                const resultadoTest = data.personal.find(
                    test => parseInt(test.idPersona, 10) === parseInt(idCuenta, 10)
                );
                if (resultadoTest && resultadoTest.idPerfil !== 0) {
                    return this.http.get(`${this._basePath}/v1/testinversor/cuenta?idCuenta=${idCuenta}`).pipe(
                        map(data2 => ({
                            status: 'finished',
                            data2
                        }))
                    );
                }
                return of({ status: 'pending', data: [] });
            })
        );
    }

    getDetalleTestCuentaJuridica(idCuenta): Observable<any> {
        return this.http.get(`${this._basePath}/testinversor/cuenta`, {
            params: { idCuenta }
        });
    }

    startTestInversor(): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.post(`${this._basePath}/testinversor`, { idPersona: auth.idPersona }).pipe(
            map(data => ({
                status: 'in_process',
                data
            }))
        );
    }

    startTestInversorByIdCuenta(idCuenta): Observable<any> {
        const idcuenta = idCuenta.toString();
        return this.http.post(`${this._basePath}/testinversor`, { idCuenta: idcuenta }).pipe(
            map(data => ({
                status: 'in_process',
                data
            }))
        );
    }

    continueTestInversor(token, idRespuesta): Observable<any> {
        return this.http.post(`${this._basePath}/testinversor/${token}`, { idRespuesta }).pipe(
            map((data: any) => ({
                status: data.perfil ? 'finished' : 'in_process',
                data
            }))
        );
    }

    getTestInversor(): Observable<any> {
        return this.http.get(`${this._basePath}/testinversor`);
    }

    getSwitchStorage(name: string): boolean {
        const loadStorage = this._storageService.get('switchStorage');
        return loadStorage ? loadStorage && loadStorage[name] : true;
    }

    setSwitchStorage(name: string, value: boolean): boolean {
        if (!name) {
            return;
        }
        const loadStorage = this._storageService.get('switchStorage');
        const payload = {
            ...loadStorage,
            [name]: value
        };
        this._storageService.set('switchStorage', payload);
        return value;
    }
}
