import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { XlsxSheetRow } from 'src/app/utils/xls';

@Injectable()
export class UtilsTranslateService {
    constructor(private _translateService: TranslateService) {}
    /** Retorna los campos traducidos */
    translateXls(data, fields: string[]): XlsxSheetRow[] {
        return data.map(row => {
            const newItem = {};
            fields.forEach((field: string) => {
                const baseTranslate = 'global.misc.xls.';
                let fieldName = this._translateService.instant(baseTranslate + field.toLowerCase());

                if (fieldName.includes('global.misc.xls.')) {
                    fieldName = field;
                }
                newItem[fieldName] = row[field];
            });
            return newItem;
        });
    }

    translateObjectinArray(field: string, array: any[]): any[] {
        return array.map(item => ({
            ...item,
            [field]: this._translateService.instant(item[field])
        }));
    }
}
