import { Injectable, TemplateRef, ViewChild } from '@angular/core';
import { DeviceService } from './device.service';
import { ModalV2Service } from './modal-v2.service';
import { ShareTextService } from './share-text.service';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './theme.service';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ShareTickerService {
    get isDarkMode(): boolean {
        return this._themeService.isDarkTheme;
    }

    get shareIcon(): string {
        return this.isDarkMode
            ? this._envService.env.assetsUrl + '076906_iconoshareblue_dark.svg'
            : 'assets/icono-share-blue.svg';
    }

    constructor(
        private _deviceService: DeviceService,
        private _shareText: ShareTextService,
        private _themeService: ThemeService,
        private _envService: EnvironmentService,
        private _translateService: TranslateService,
        private _modalService: ModalV2Service
    ) {}
    get isResponsive(): boolean {
        return this._deviceService.is(['mobile']);
    }
    async openShareTickerModal(item, template, tipo?, tipoTicker?): Promise<void> {
        let varAbsolutaAux;
        let varPorcentualAux;
        const variacion = item?.Variacion || item?.mc;
        if (item.VariacionAbsoluta === undefined || item.VariacionPorcentual === undefined) {
            if (variacion) {
                if (variacion.match(/=0 \(0%\)/) || variacion.match(/=0.00 \(0.00%\)/)) {
                    varAbsolutaAux = 0;
                    varPorcentualAux = 0;
                } else {
                    varAbsolutaAux = +variacion.match(/[-]?[0-9]\d*(\.\d+)?/)[0];
                    varPorcentualAux = +variacion.match(/[-]?\d+.\d+%/)[0].slice(0, -1);
                }
            } else {
                varAbsolutaAux = item.variacionAbs;
                varPorcentualAux = item.variacionPorcentual;
            }
        } else {
            varAbsolutaAux = item.VariacionAbsoluta;
            varPorcentualAux = item.VariacionPorcentual;
        }
        const ultimoPrecio = item?.UltimoPrecio || item?.u || item?.Precio;
        const text = this._shareText.generateText(
            item?.panel,
            item?.ticker,
            varAbsolutaAux,
            varPorcentualAux,
            ultimoPrecio,
            item?.idMoneda || item?.idMo
        );

        const { ticker, idPlazo } = item;
        const share_id = uuidv4();

        const data = {
            data: {
                requierelogin: '1',
                namespace: tipo === 'DetallePack' ? 'Core.Business.DetallePack' : 'Core.Business.DetalleInstrumento',
                ticker: ticker,
                panel: item.panel?.toString(),
                plazo: item.plazo,
                idPlazo: idPlazo,
                share_id
            }
        };

        if (item.panel === 64) {
            data.data['namespace'] = 'Core.Business.Operar.Treasuries';
            data.data['idMoneda'] = item.idMo;
        }

        if (tipo?.toLowerCase() === 'fondos') {
            data.data['fondo'] = tipoTicker;
        }
        if (tipo === 'DetallePack') {
            data.data['detallePack'] = tipoTicker;
        }
        const token = window.btoa(JSON.stringify(data));
        const _baseUrlShare = this._envService.env.baseUrlDeeplink;
        const url = `${_baseUrlShare}/link?data=${token}`;
        if (navigator.share && this.isResponsive) {
            try {
                const shareData = {
                    title: 'Ticker',
                    text,
                    url
                };
                await navigator.share(shareData);
            } catch (err) {}
        } else {
            this._modalService.open({
                template: template,
                title: this._translateService.instant('global.components.modalTemplates.shareTicker.title'),
                titleIcon: this.shareIcon,
                type: 'modal',
                context: {
                    url,
                    text,
                    share_id,
                    ticker
                }
            });
        }
    }
}
