import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { IConfig, NgxMaskModule } from 'ngx-mask';

// components
import { ItemGenericoComponent } from './components/item-generico/item-generico.component';
import { SelectGenericoComponent } from './components/select-generico/select-generico.component';
import { CheckboxGenericoComponent } from './components/checkbox-generico/checkbox-generico.component';
import { PaginatedListComponent } from './components/paginated-list/paginated-list.component';
import { PaginatedListItemComponent } from './components/paginated-list/components/paginated-list-item/paginated-list-item.component';
import { PaginatedListItemDefaultComponent } from './components/paginated-list/components/paginated-list-item-default/paginated-list-item-default.component';
import { PaginatedListFooterComponent } from './components/paginated-list/components/paginated-list-footer/paginated-list-footer.component';
import { PagerComponent } from './components/pager/pager.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import {
    AccordionItemComponent,
    AccordionItemContentComponent,
    AccordionItemHeaderComponent
} from './components/accordion/accordion-item.component';
import { SelectMultipleComponent } from './components/select-multiple/select-multiple.component';
import { ButtonActionsComponent } from './components/button-actions/button-actions.component';
import { ButtonExpandComponent } from './components/button-expand/button-expand.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ModalAlertaComponent } from './components/modal-alerta/modal-alerta.component';
import { SeuoComponent } from './components/seuo/seuo.component';
import { WritableSelectComponent } from './components/writable-select/writable-select.component';
import { HideMoneyComponent } from './components/hide-money/hide-money.component';
import { ErrorPlaceholderComponent } from './components/error-placeholder/error-placeholder.component';

// directives
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { TooltipArrowDirective } from './directives/tooltip/tooltip-arrow.directive';
import { AclDirective } from './directives/acl.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { LoadingDirective } from './directives/loading.directive';
import { PermissionsDirective } from './directives/permissions.directive';
import { ExchangeVariationDirective } from './directives/exchange-variations.directive';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { DragDropDirective } from './directives/drag-drop.directive';

// pipes
import { FilterPipe } from './pipes/filter.pipe';
import { FormatoPesosPipe } from './pipes/formato-pesos-pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { MonedaPipe } from './pipes/moneda.pipe';
import { TrimString } from './pipes/trimStr.pipe';
import { SanitizerPipe } from './pipes/sanitizer.pipe';
import { BaseUrlPipe } from './pipes/prefix-url.pipe';
import { FormatoMonedaPipe } from './pipes/formato-moneda.pipe';
import { EstiloVariacionPipe } from './pipes/estilo-variacion.pipe';
import { CuilPipe } from './pipes/cuil.pipe';
import { CalculovarcotPipe } from './pipes/calculovarcot.pipe';
import { GenericSearchInstrumentsComponent } from '../components/generic-search-instruments/generic-search-instruments.component';
import { ToBoldPipe } from './pipes/to-bold.pipe';
import { InputCurrencyComponent } from '../components/input-currency/input-currency.component';
import { NotNaNPipe } from './pipes/not-na-n.pipe';
import { ElipsisPipe } from './pipes/elipsis.pipe';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { TablaInstrumentosComponent } from './components/tabla-instrumentos/tabla-instrumentos.component';
import { LogoTickerModule } from '../components/logo-ticker/logo-ticker.module';
import { OrderByComponent } from './directives/ordering/table-order-by.directive';
import { OrderingTableDirective } from './directives/ordering/table-ordering.directive';
import { BankAccountSelectorComponent } from './components/bank-account-selector/bank-account-selector';
import { BzServicesModule } from '@balanz/services';
import { IconHideMoneyComponent } from './components/icon-hide-money/icon-hide-money.component';
import { ParseZeroPipe } from './pipes/parse-zero.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { SentimientoDeMercadoComponent } from './components/sentimiento-de-mercado/sentimiento-de-mercado.component';

const maskConfig: Partial<IConfig> = {
    validation: false
};

@NgModule({
    declarations: [
        ItemGenericoComponent,
        BankAccountSelectorComponent,
        TrimString,
        SelectGenericoComponent,
        CheckboxGenericoComponent,
        PaginatedListComponent,
        PaginatedListItemComponent,
        PaginatedListItemDefaultComponent,
        PaginatedListFooterComponent,
        PagerComponent,
        AccordionComponent,
        AccordionItemComponent,
        AccordionItemContentComponent,
        AccordionItemHeaderComponent,
        SelectMultipleComponent,
        PermissionsDirective,
        ExchangeVariationDirective,
        ButtonActionsComponent,
        ButtonExpandComponent,
        ProgressComponent,
        TooltipDirective,
        TooltipArrowDirective,
        MonedaPipe,
        FormatoPesosPipe,
        FilterPipe,
        HighlightPipe,
        MomentPipe,
        SanitizerPipe,
        BaseUrlPipe,
        AclDirective,
        DraggableDirective,
        ImageFallbackDirective,
        LoadingDirective,
        ModalAlertaComponent,
        FormatoMonedaPipe,
        SeuoComponent,
        EstiloVariacionPipe,
        CuilPipe,
        CalculovarcotPipe,
        GenericSearchInstrumentsComponent,
        WritableSelectComponent,
        ClickedOutsideDirective,
        ToBoldPipe,
        InputCurrencyComponent,
        HideMoneyComponent,
        ErrorPlaceholderComponent,
        DragDropDirective,
        NotNaNPipe,
        ElipsisPipe,
        AutoFocusDirective,
        TablaInstrumentosComponent,
        OrderByComponent,
        OrderingTableDirective,
        IconHideMoneyComponent,
        ParseZeroPipe,
        CapitalizeFirstPipe,
        SentimientoDeMercadoComponent,
        CapitalizeFirstPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgSelectModule,
        TranslateModule,
        NgxMaskModule.forRoot(maskConfig),
        LogoTickerModule,
        BzServicesModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ItemGenericoComponent,
        BankAccountSelectorComponent,
        TrimString,
        SelectGenericoComponent,
        CheckboxGenericoComponent,
        PaginatedListComponent,
        PaginatedListItemComponent,
        PaginatedListItemDefaultComponent,
        PaginatedListFooterComponent,
        PagerComponent,
        AccordionComponent,
        AccordionItemComponent,
        AccordionItemContentComponent,
        AccordionItemHeaderComponent,
        SelectMultipleComponent,
        ButtonActionsComponent,
        ButtonExpandComponent,
        ProgressComponent,
        TooltipDirective,
        TooltipArrowDirective,
        PermissionsDirective,
        ExchangeVariationDirective,
        MonedaPipe,
        FormatoPesosPipe,
        FilterPipe,
        HighlightPipe,
        MomentPipe,
        SanitizerPipe,
        BaseUrlPipe,
        AclDirective,
        DraggableDirective,
        ImageFallbackDirective,
        LoadingDirective,
        ModalAlertaComponent,
        FormatoMonedaPipe,
        SeuoComponent,
        TranslateModule,
        EstiloVariacionPipe,
        CuilPipe,
        CalculovarcotPipe,
        GenericSearchInstrumentsComponent,
        WritableSelectComponent,
        NgSelectModule,
        ClickedOutsideDirective,
        ToBoldPipe,
        InputCurrencyComponent,
        HideMoneyComponent,
        ErrorPlaceholderComponent,
        DragDropDirective,
        NotNaNPipe,
        ElipsisPipe,
        AutoFocusDirective,
        TablaInstrumentosComponent,
        OrderByComponent,
        OrderingTableDirective,
        BzServicesModule,
        IconHideMoneyComponent,
        ParseZeroPipe,
        CapitalizeFirstPipe,
        SentimientoDeMercadoComponent,
        CapitalizeFirstPipe
    ]
})
export class SharedModule {}
