import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventLogger } from '../../shared/models/logger';
import { AuthService } from './auth.service';
import moment from 'moment';
import { BzStorageService } from '@balanz/services';
import { EnvironmentService } from './environment.service';
import { Subscription, timer } from 'rxjs';

@Injectable()
export class LoggerService implements OnDestroy {
    private _basePath = this._envService.env.BaseUrlLoggerService + '/logger-event';
    private sub: Subscription;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private _envService: EnvironmentService,
        private _storageService: BzStorageService
    ) {
        this.sub = timer(100, 60 * 1000).subscribe(() => {
            this.sendEventLoggerQueue();
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public async registrarEvento(evento: EventLogger,forceSend = false) {
        if (this._envService.env.LoggerEnabled) {
            const currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
            const auth = this.authService.getCurrentUser();
            evento.uid = this.generateUid();
            evento.user = auth ? auth.idPersona : 'N/A';
            evento.timestamp = currentDate;
            evento.timestamp_client = currentDate;
            evento.extra = this.convertObjToString(evento.extra);

            if (!this.errorWasSent(evento) || forceSend) {
                try {
                    this.lastErrorTriedSend(evento);
                    await this.register(evento);
                    this.sendEventLoggerQueue();
                } catch (error) {
                    this.addEventLoggerToQueue(evento);
                }
            }
        }
    }

    private async register(evento: EventLogger): Promise<object> {
        return this.http
            .post(
                this._basePath,
                {
                    Event: evento
                },
                {
                    params: {
                        app: this._envService.env.LoggerAppName
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                }
            )
            .toPromise();
    }

    private generateUid(digits = 10): number {
        let max = '9';
        let min = '1';
        for (let i = 0; i < digits - 1; i++) {
            max = max + '9';
            min = min + '0';
        }
        return Math.floor(Math.random() * (parseInt(max, 10) - parseInt(min, 10))) + parseInt(min, 10);
    }

    private convertObjToString(object): string {
        if (typeof object === 'object') {
            return Object.entries(object)
                .map(item => `${item[0]}:${item[1]}`)
                .join(';');
        } else if (typeof object !== 'string') {
            return '';
        }
    }

    private addEventLoggerToQueue(event: any): void {
        const queuEventErrorLogger = this._storageService.get('queue_event_error_logger') || [];
        const existsError = queuEventErrorLogger.find(item => item.description === event.description);
        if (!existsError) {
            queuEventErrorLogger.push(event);
            this._storageService.set('queue_event_error_logger', queuEventErrorLogger);
        }
    }

    private async sendEventLoggerQueue(): Promise<void> {
        const queuEventErrorLogger = this._storageService.get('queue_event_error_logger') || [];
        if (queuEventErrorLogger.length > 0) {
            const evento = queuEventErrorLogger.pop();
            try {
                this.lastErrorTriedSend(evento);
                await this.register(evento);
                this._storageService.set('queue_event_error_logger', queuEventErrorLogger);
                this.sendEventLoggerQueue();
            } catch (error) {
                this.addEventLoggerToQueue(evento);
            }
        }
    }

    private errorWasSent(event: any): boolean {
        const lastEvent: any = this._storageService.get('last_event_error_sent');
        return lastEvent && lastEvent.description === event.description;
    }

    private lastErrorTriedSend(event: any): void {
        this._storageService.set('last_event_error_sent', event);
    }
}
