import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuentaService } from './cuenta.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class OrdenService {
    private _basePath = this._envService.env.BaseUrlService + '/ordenes';

    constructor(
        private http: HttpClient,
        private cuentaService: CuentaService,
        private _envService: EnvironmentService
    ) {}

    getDetalle(idOrden: number): Observable<any> {
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get(`${this._basePath}/detalle/${cuenta.idCuenta}/${idOrden}`)
            .pipe(map((item: any) => item.Ordenes));
    }

    cancelOrden(orden): Observable<any> {
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http.post(`${this._envService.env.BaseUrlService}/operar/cancelar`, {
            ...orden,
            idCuenta: cuenta.idCuenta
        });
    }
}
