import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventSortableColumn } from '../../shared/models/sortable-table';

@Injectable()
export class SortService {
    private _sortEvent: Subject<EventSortableColumn> = new Subject();

    sort(event: EventSortableColumn): void {
        this._sortEvent.next(event);
    }

    listenSort(): Observable<EventSortableColumn> {
        return this._sortEvent.asObservable();
    }
}
