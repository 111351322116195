import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { AuthService } from '../services/auth.service';
import { CuentaService } from './cuenta.service';

@Injectable()
export class BancoService {
    private apiUrl = this._envService.env.BaseUrlService;

    constructor(
        private http: HttpClient,
        private _envService: EnvironmentService,
        private _authService: AuthService,
        private _cuentaService: CuentaService
    ) {}

    getBancos(): Observable<any> {
        return this.http.get(`${this.apiUrl}/bancos`).pipe(map((item: any) => item.bancos));
    }

    getBrokers(): Observable<any> {
        return this.http.get(this.apiUrl + '/cuentasbrokers');
    }

    validateBankAccount(CBUAlias, idCuenta): Observable<any> {
        const auth = this._authService.getCurrentUser();
        const payload = idCuenta === -1 ? { idPersona: auth.idPersona } : { idCuenta: idCuenta };
        return this.http.post(this.apiUrl + '/cbu/validar', { ...payload, CBUAlias });
    }
}
