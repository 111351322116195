import { Injectable } from '@angular/core';
import { Movimiento } from '../../shared/models/movimiento';
import { Observable, EMPTY } from 'rxjs';
import moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CuentaService } from './cuenta.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class MovimientoService {
    private _basePath = this._envService.env.BaseUrlService;

    constructor(
        private http: HttpClient,
        private cuentaService: CuentaService,
        private _envService: EnvironmentService
    ) {}

    getMovimientos(fechaDesde?, fechaHasta?): Observable<any> {
        fechaDesde = fechaDesde
            ? moment(fechaDesde).format('YYYYMMDD')
            : moment()
                  .subtract(1, 'weeks')
                  .format('YYYYMMDD');
        fechaHasta = fechaHasta ? moment(fechaHasta).format('YYYYMMDD') : moment().format('YYYYMMDD');
        const cuenta = this.cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this.http
            .get<Movimiento[]>(`${this._basePath}/movimientos/${cuenta.idCuenta}`, {
                params: { FechaDesde: fechaDesde, FechaHasta: fechaHasta, ic: 0 }
            })
            .pipe(
                map((item: any) => {
                    let movimientosDolar: Movimiento[];
                    const movimientos: Movimiento[] = item.movimiento;
                    if (item.hasOwnProperty('movimientoDolarBalanz')) {
                        movimientosDolar = item.movimientoDolarBalanz;
                    }
                    return { movimientos: movimientos, movimientosDolar: movimientosDolar };
                })
            );
    }

    getPDFOperacion(reporte, codigo): Observable<any> {
        const APIEndpoint = this._envService.env.BaseUrlService + '/descargar/' + reporte + '/' + codigo;
        return this.http.get(APIEndpoint, { observe: 'response', responseType: 'blob' });
    }
}
