import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable()
export class WidgetsService {
    // Setear aqui los widgets a utilizar en la app
    // luego suscribirse a listenWidgetLoaded() para saber si el codigo js del widget
    // fue cargado correctamente y puede usarse
    private widgets = [this._envService.env.BaseUrlWidgetsLibs + this._envService.env.WidgetTradingView];
    private widgetLoadedSub: ReplaySubject<string>;

    constructor(private _envService: EnvironmentService, @Inject(DOCUMENT) private document: Document) {
        if (!this._envService.env.isCustomerImpersonate) {
            this.widgets.push(this._envService.env.BaseUrlWidgetsLibs + this._envService.env.WidgetChatElement);
            this.widgets.push(this._envService.env.BaseUrlWidgetsLibs + this._envService.env.WidgetFaqsElement);
        }
        if (this._envService.env.showWidgetCalificacion) {
            this.widgets.push(this._envService.env.BaseUrlWidgetsLibs + this._envService.env.WidgetCalificacionElement);
        }

        if (this._envService.env.showWidgetGenBotAi) {
            this.widgets.push(this._envService.env.BaseUrlWidgetsLibs + this._envService.env.WidgetGenBotAiUri);
        }

        this.widgetLoadedSub = new ReplaySubject(this.widgets.length);
    }

    getWidgets(): string[] {
        return this.widgets;
    }

    setWidgetLoaded(widgetUrl: string): void {
        this.widgetLoadedSub.next(widgetUrl);
    }

    listenWidgetLoaded(): Observable<string> {
        return this.widgetLoadedSub.asObservable();
    }
}
