import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalV2Component } from './modal-v2.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [ModalV2Component],
    imports: [SharedModule, BrowserAnimationsModule],
    exports: [ModalV2Component]
})
export class ModalV2Module {}
