import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import { EventLogger } from '../../shared/models/logger';
import { VERSION } from 'src/environments/version';
import { EnvironmentService } from '../services/environment.service';
import { AuthService } from '../services/auth.service';
import { BzStorageService } from '@balanz/services';

@Injectable()
export class BaseExceptionHandler implements ErrorHandler {
    constructor(
        private loggerService: LoggerService,
        private _envService: EnvironmentService,
        private _storageService: BzStorageService,
        private _authService: AuthService
    ) {}

    handleError(error: any): void {
        const event: EventLogger = {
            type: 'application',
            description: this.cutText(error.message),
            status: 1000,
            module: 'GlobalErrors', // TODO: Capturar modulo donde proviene el error
            detail: this.cutText(error.stack),
            version: VERSION.version + ' ' + VERSION.hash,
            error: this.cutText(this.getErrorJSON(error)),
            s_t: this.getSourceMapParams(error),
            userAgent: window.navigator.userAgent,
            session: this._authService.getCurrentUser()?.idSesion ?? 'N/A'
        };
        if (this._envService.env.consoleLogging) {
            console.error(error);
        }
        this.loggerService.registrarEvento(event);

        const chunkFailedMessage = /Loading chunk (\S+) failed/;
        if (chunkFailedMessage.test(error.message)) {
            if (this._storageService.get('chunkreloaded') === undefined) {
                this._storageService.set('chunkreloaded', true);
                window.location.reload();
            }
        }
    }

    private cutText(text: any): string {
        try {
            if (typeof text === 'string') {
                return text.slice(0, 2048);
            } else if (typeof text === 'object') {
                return JSON.stringify(text).slice(0, 2048);
            } else {
                return text.toString().slice(0, 2048);
            }
        } catch (_) {
            return '';
        }
    }

    private getErrorJSON(error: any): string {
        try {
            const properties = Object.getOwnPropertyNames(error);
            const errorObject = properties.map(key => ({ key, value: error[key] }));
            return JSON.stringify(errorObject);
        } catch (_) {
            return '';
        }
    }

    private getSourceMapParams(error: any): string {
        try {
            if (['line', 'column', 'sourceURL'].every(prop => Object.getOwnPropertyNames(error).includes(prop))) {
                const stFile = error.sourceURL.split('/').pop();
                return [stFile + '.map', error.line, error.column].join(' ');
            } else {
                const pattern = /https:\/\/.*\.balanz\.com\/.*\.js:[0-9]*:[0-9]*/;
                const [stFile, stLine, stColumn] = error.stack
                    .match(pattern)[0]
                    .split('/')
                    .pop()
                    .split(':');
                return [stFile + '.map', stLine, stColumn].join(' ');
            }
        } catch (_) {
            /*
                El stack no es standard, por lo que si no coincide con el formato que nosotros conocemos, no mandamos
                nada. Igualmente, en el campo detail se esta enviando el stack completo, por lo que se puede revisar
                manualmente.

                Referencia: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Stack
            */
            return '';
        }
    }
}
