import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
    constructor(private _envService: EnvironmentService, private _router: Router) {}
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this._envService.env.maintenance) {
            return true;
        }
        this._router.navigate(['/auth']);
        return true;
    }
}
