import { AuthService } from '../services/auth.service';
import { BzStorageService } from '@balanz/services';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private _authService: AuthService,
        private _storageService: BzStorageService,
        private _router: Router,
        private _envService: EnvironmentService,
        private _translateService: TranslateService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this._authService.getAccessToken();

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: token
                }
            });
        }

        const lang = this._translateService.currentLang;

        if (lang) {
            request = request.clone({
                setHeaders: {
                    Lang: lang
                }
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        if (!request.headers.has('Accept')) {
            request = request.clone({
                headers: request.headers.set('Accept', 'application/json')
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const avoidRedirect = request.params.get('avoidAuthRedirect');
                const needAvoid =
                    (avoidRedirect !== null && typeof avoidRedirect === 'boolean' && avoidRedirect === true) ||
                    (typeof avoidRedirect === 'string' && avoidRedirect.toLowerCase() === 'true');
                if (!needAvoid && (error.status === 401 || error.status === 403)) {
                    this._storageService.clear();
                    const forwardUrl: string = this._router.routerState.snapshot.url.split('?')[0];
                    const queryParams: Params = this._router.routerState.snapshot.root.queryParams;
                    if (forwardUrl) {
                        this._storageService.set('forward', forwardUrl);
                        if (queryParams) {
                            this._storageService.set('forwardParams', queryParams);
                        }
                    } else {
                        throw Error('Error al intentar obtener la url actual.');
                    }

                    if (this._envService.env.isCustomerImpersonate) {
                        this._router.navigate(['/app/invalid']);
                    } else {
                        window.location.href = '/';
                    }
                }
                return throwError(error);
            })
        );
    }
}
