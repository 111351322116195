import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';
import { NotificacionesService } from '../services/notificaciones.service';

@Injectable()
export class NotificationsResolver implements Resolve<void> {
    constructor(private _envService: EnvironmentService, private _notificationsService: NotificacionesService) {}

    resolve(): Observable<void> | Promise<void> | void {
        if (!this._envService.env.isCustomerImpersonate) {
            this._notificationsService.initIntervalNotifications();
        }
    }
}
