import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterModule, Routes } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// libs
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// modules
import { BalanzTrackerModule } from './components/balanz-tracker/balanz-tracker.module';
import { ModalV2Module } from './components/modal-v2/modal-v2.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

// guards
import { UnloggedLayoutGuard } from './core/guards/unlogged-layout.guard';
import { LoggedLayoutGuard } from './core/guards/logged-layout.guard';
import { MaintenanceGuard } from './core/guards/maintenance.guard';

// resolvers
import { NotificationsResolver } from './core/resolvers/notifications-resolver.service';
import { InstrumentosResolver } from './core/resolvers/instrumentos-resolver.service';
import { CuentaResolver } from './core/resolvers/cuenta-resolver.service';
import { ParametrosNegocioResolver } from './core/resolvers/parametros-negocio-resolver.service';
import { BannersResolver } from './core/resolvers/banners-resolver.service';
import { RefeshModalComponent } from './components/refresh-modal/refresh.component';
import { DeeplinkComponent } from './components/deeplink/deeplink.component';
import { BzDesignSystemModule } from '@balanz/design-system';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
    {
        path: 'app',
        resolve: [
            CuentaResolver,
            NotificationsResolver,
            ParametrosNegocioResolver,
            BannersResolver,
            InstrumentosResolver
        ],
        canActivate: [LoggedLayoutGuard],
        canLoad: [LoggedLayoutGuard],
        loadChildren: () => import('./layouts/logged-layout/logged-layout.module').then(m => m.LoggedLayoutModule)
    },
    {
        path: 'auth',
        canActivate: [UnloggedLayoutGuard],
        canLoad: [UnloggedLayoutGuard],
        loadChildren: () => import('./layouts/unlogged-layout/unlogged-layout.module').then(m => m.UnloggedLayoutModule)
    },
    {
        path: 'maintenance',
        canActivate: [MaintenanceGuard],
        loadChildren: () =>
            import('./layouts/maintenance-layout/maintenance-layout.module').then(m => m.MaintenanceLayoutModule)
    },
    {
        path: 'link',
        component: DeeplinkComponent
    },
    {
        path: '**',
        redirectTo: 'auth',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [AppComponent, RefeshModalComponent],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        OverlayModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.checkForUpdates }),
        SharedModule,
        BalanzTrackerModule.forRoot(),
        CoreModule.forRoot(),
        BzDesignSystemModule.forRoot({
            cmsBasePath: 'https://cms.balanz.com/PFS/'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/'),
                deps: [HttpClient]
            }
        }),
        ModalV2Module
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
