import { Injectable } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { BehaviorSubject, Observable } from 'rxjs';
import { BzStorageService } from '@balanz/services';
import { DeviceService } from './device.service';
import { EnvironmentService } from './environment.service';
import { BzUserBehaviourService } from '@balanz/services';
import { ParametrosService } from './parametros.service';
import { ParametrosPersona } from 'src/app/shared/models/parametros-persona';
import { AuthService } from './auth.service';

export type AppTheme = 'light' | 'dark';

@Injectable()
export class ThemeService {
    private _currentTheme: BehaviorSubject<AppTheme>;

    get isMobile(): boolean {
        return this._deviceService.is(['mobile']);
    }

    get isResponsive(): boolean {
        return this._deviceService.is(['mobile', 'tablet']);
    }

    constructor(
        private _storageService: BzStorageService,
        private _deviceService: DeviceService,
        private envService: EnvironmentService,
        private _userBehaviourService: BzUserBehaviourService,
        private _parametrosService: ParametrosService,
        private _authService: AuthService
    ) {
        let defaultTheme: AppTheme = 'light';
        if (this.isResponsive && this.envService.env.ThemeSupport) {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                defaultTheme = 'dark';
            }
        }

        this._currentTheme = new BehaviorSubject<AppTheme>(defaultTheme);
        this.changeTheme(defaultTheme, null, true);
        if (window.matchMedia && this.envService.env.ThemeSupport) {
            ['light', 'dark'].forEach((theme: AppTheme) => {
                window.matchMedia(`(prefers-color-scheme: ${theme})`).addEventListener('change', event => {
                    if (event.matches && this.isResponsive) {
                        this.changeTheme(theme, null, true);
                    }
                });
            });
        }
    }

    get isDarkTheme(): boolean {
        return this._currentTheme.value === 'dark';
    }

    switchTheme(idPersona: string, omitSave: boolean = false): void {
        let newTheme: AppTheme;

        switch (this._currentTheme.value) {
            case 'dark':
                newTheme = 'light';
                break;
            default:
                newTheme = 'dark';
                break;
        }

        this._userBehaviourService.sendEvent({
            category: 'darkmode',
            name: 'darkmode.click_turn_' + (newTheme === 'dark' ? 'on' : 'off'),
            description: `Event registered when an user clicks on ${newTheme} mode`
        });

        this.changeTheme(newTheme, idPersona, omitSave);
    }

    onThemeChanged(): Observable<AppTheme> {
        return this._currentTheme.asObservable();
    }

    am4themes_animated(target) {
        if (target instanceof am4core.SpriteState) {
            target.transitionDuration = 400;
        }
        if (target instanceof am4core.Component) {
            target.rangeChangeDuration = 500;
            target.interpolationDuration = 500;
            target.sequencedInterpolation = false;
            if (target instanceof am4charts.SankeyDiagram) {
                target.sequencedInterpolation = true;
            }
            if (target instanceof am4charts.FunnelSeries) {
                target.sequencedInterpolation = true;
            }
        }
        if (target instanceof am4charts.Chart) {
            target.defaultState.transitionDuration = 2000;
            target.hiddenState.transitionDuration = 1000;
        }
        if (target instanceof am4core.Tooltip) {
            target.animationDuration = 400;
            target.defaultState.transitionDuration = 400;
            target.hiddenState.transitionDuration = 400;
        }
        if (target instanceof am4core.Scrollbar) {
            target.animationDuration = 500;
        }
        if (target instanceof am4charts.Series) {
            target.defaultState.transitionDuration = 1000;
            target.hiddenState.transitionDuration = 700;
            target.hiddenState.properties.opacity = 1;
            target.showOnInit = true;
        }
        if (target instanceof am4charts.FunnelSlice) {
            target.defaultState.transitionDuration = 800;
            target.hiddenState.transitionDuration = 1000;
            target.hiddenState.properties.opacity = 1;
        }
        if (target instanceof am4core.Slice) {
            target.defaultState.transitionDuration = 700;
            target.hiddenState.transitionDuration = 1000;
            target.hiddenState.properties.opacity = 1;
        }
        if (target instanceof am4core.Preloader) {
            target.hiddenState.transitionDuration = 2000;
        }
        if (target instanceof am4charts.Column) {
            target.defaultState.transitionDuration = 700;
            target.hiddenState.transitionDuration = 1000;
            target.hiddenState.properties.opacity = 1;
        }
        if (target instanceof am4charts.Column3D) {
            target.hiddenState.properties.opacity = 0;
        }
    }

    am4themes_lightTheme(target) {
        if (target instanceof am4core.InterfaceColorSet) {
            target.setFor('text', am4core.color('#000'));
            target.setFor('grid', am4core.color('#000'));
        }
    }

    am4themes_darkTheme(target) {
        if (target instanceof am4core.InterfaceColorSet) {
            target.setFor('text', am4core.color('#fff'));
            target.setFor('grid', am4core.color('#fff'));
        }
    }

    changeTheme(newTheme: AppTheme, idPersona: string, omitSave: boolean = false) {
        if (newTheme === 'dark') {
            document.body.classList.remove('light-theme');
            document.body.classList.add('dark-theme');
            // BDS
            document.body.classList.add('bz-bds-theme-dark');
        } else {
            document.body.classList.remove('dark-theme');
            document.body.classList.add('light-theme');
            // BDS
            document.body.classList.remove('bz-bds-theme-dark');
        }
        this._currentTheme.next(newTheme);
        if (!omitSave) {
            const newThemePayload: ParametrosPersona = { Nombre: 'clientesWebTheme', Valor: newTheme };
            this._parametrosService.setParametroPersona(newThemePayload).subscribe(_ => {
                const parametrosAux = this._authService.getCurrentUser().Parametros;
                parametrosAux.clientesWebTheme = newTheme;
                this._authService.updateCurrentUser({ Parametros: parametrosAux });
            });
        }
    }

    amChartThemeUpdate() {
        am4core.unuseTheme(!this.isDarkTheme ? this.am4themes_darkTheme : this.am4themes_lightTheme);
        am4core.useTheme(this.isDarkTheme ? this.am4themes_darkTheme : this.am4themes_lightTheme);
        am4core.useTheme(this.am4themes_animated);
    }
}
