import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ParametrosService } from '../services/parametros.service';

@Injectable()
export class ParametrosResolver implements Resolve<void> {
    constructor(private _parametrosNegocioService: ParametrosService) {}

    resolve(): Observable<void> | Promise<void> | void {
        this._parametrosNegocioService.loadBussinessParams();
    }
}
