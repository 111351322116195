import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CuentaService } from './cuenta.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class CaucionService {
    private _basePath = this._envService.env.BaseUrlService + '/cauciones';

    constructor(
        private _http: HttpClient,
        private _cuentaService: CuentaService,
        private _envService: EnvironmentService
    ) {}

    getDetalle(): Observable<any> {
        const cuenta = this._cuentaService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.get(`${this._basePath}/${cuenta.idCuenta}`);
    }
}
