import { Injectable } from '@angular/core';
import { CurrencyFormatterService } from './currency-formatter.service';

@Injectable()
export class ShareTextService {
    constructor(private _currencyFormatter: CurrencyFormatterService) {}

    generateText = (
        panel: number,
        ticker: string,
        varAbs: number,
        varPor: number,
        ultPrecio: number,
        idMoneda: number
    ) => {
        let text = '';
        if (panel === 34) {
            text += 'Caución ' + ticker || '';
            text +=
                varAbs > 0
                    ? ` subió a una tasa de `
                    : varAbs < 0
                    ? ` bajó a una tasa de `
                    : ' está actualmente en una tasa de ';
            text += `${ultPrecio || ''}%. Podés seguirlo en Balanz: `;
        } else if([10,12,64].includes(panel)) {
        // Fondos Y Treasuries
        text = `Seguí el ticker ${ticker} en Balanz: `;
        } else {
            text += ticker || '';
            text +=
                varAbs > 0
                    ? ` subió ${varPor || ''}% a `
                    : varAbs < 0
                    ? ` bajó ${varPor || ''}% a `
                    : ' está actualmente en ';
            text += `${this._currencyFormatter.format(ultPrecio, idMoneda) || ''}. Podés seguirlo en Balanz: `;
        }
        return text;
    };
}
