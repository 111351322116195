import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogoTickerComponent } from './logo-ticker.component';

@NgModule({
    declarations: [LogoTickerComponent],
    imports: [CommonModule],
    exports: [LogoTickerComponent]
})
export class LogoTickerModule {}
