import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, EMPTY, BehaviorSubject } from 'rxjs';
import { CuentaService } from './cuenta.service';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LayoutService } from '../services/layout.service';
import { EnvironmentService } from '../services/environment.service';
import { replaceNewLineByBrTag } from './../../utils/html';
import { Conformidad } from './../../shared/models/conformidad.model';

export interface PropsOperar {
    palabraBold?: string;
    operacion?: string;
    tipo?: any;
    tipoOperacion?: number;
    idSintetico?: number;
    Cantidad?: string;
    composicionPacks?: any;
    Observaciones?: string;
    idTipoOperacion?: number;
    idCuenta?: number;
    ticker?: string;
    idFondo?: number;
    idOperacion?: number;
    idMoneda?: number;
    idMonedaReturn?: number;
    panel?: number;
    plazo?: number;
    event?: 'open' | 'close' | 'confirm' | 'completed' | 'reset';
    typeEvent?: 'success' | 'error';
    message?: string;
    mode?: 'window' | 'drawer';
    activeTab?: string;
    iniciarTest?: boolean;
    detalleTest?: any;
    headerTitle?: string;
    title?: string;
    modalTitle?: string;
    modalSubtitle?: string;
    secondaryMsg?: string;
    reportDeposit?: boolean;
    status?: boolean;
    doubleLineString?: boolean;
    idPrecarga?: number;
    body?: any;
    refreshData?: boolean;
    eliminarAlerta?: any;
    eliminarWatchlist?: any;
    eliminarTickerWatchlist?: any;
    esEliminarWatchlist?: boolean;
    watchlistSelected?: any;
    sizeModal?: string;
    cotizacion?: any;
    descripcionCuenta?: string;
    cuenta?: any;
    info?: boolean;
    eventFrom?: string;
    volver?: boolean;
    instrumento?: string;
    accion?: string;
    montoDisponible?: number;
    z_index?: number;
    step?: number;
    licitacion?: number;
    especie?: number;
    infoOperacion?: string;
    generateOpCode?: boolean;
    savedProps?: PropsOperar;
    tickerBase?: string;
    noInstrumento?: boolean;
    showVolver?: boolean;
    item?: any;
    tipoDeOrden?: string;
    showRecomendations?: boolean;
    precioCompra?: any;
    precioVenta?: any;
    securityID?: string;
    modalHeaderTitle?: string;
    isFavoritos?: boolean;
}

interface ModalInfoProps {
    doubleLineString?: boolean;
    status?: boolean;
    headerTitle?: string;
    refreshData?: boolean;
    modalTitle?: string;
    secondaryMsg?: string;
    modalSubtitle?: string;
    info?: boolean;
}

@Injectable()
export class OperarService {
    private _basePath = this._envService.env.BaseUrlService;
    private _propsOperarSubject: BehaviorSubject<PropsOperar>;
    private _cacheEspecies = {};

    constructor(
        private _http: HttpClient,
        private _accountsService: CuentaService,
        private _authService: AuthService,
        private _layoutService: LayoutService,
        private _envService: EnvironmentService
    ) {
        this._propsOperarSubject = new BehaviorSubject<PropsOperar>({
            tipo: 'Acciones',
            ticker: '',
            plazo: this._authService.getPermission(`operar/acciones/plazoDefault`) || 0,
            panel: 4
        });
    }

    getEspecies(panel, plazo?, paramsCuenta = true): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta || [null, undefined].includes(plazo)) {
            const key = panel;
            if (this._cacheEspecies[key]) {
                return of(this._cacheEspecies[key]);
            }
            return this._http
                .get(`${this._envService.env.BaseUrlService}/especies`, {
                    params: {
                        panel
                    }
                })
                .pipe(
                    map(data => {
                        this._cacheEspecies[key] = data['especies'];
                        return data['especies'];
                    })
                );
        }
        const params: any = {
            panel,
            plazo
        };
        if (paramsCuenta) {
            params.idCuenta = cuenta.idCuenta;
        }
        return this._http
            .get(`${this._envService.env.BaseUrlService}/especies`, { params })
            .pipe(map(data => data['especies']));
    }

    operarEspecie(especie): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http
            .post(`${this._basePath}/operar/especies/solicitud`, { ...especie, idCuenta: cuenta.idCuenta })
            .pipe(
                map((data: any) => {
                    data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                    return data;
                })
            );
    }

    /* Precargar en true cuando se usa para generar una precarga desde asesores */
    operarEspecieCuenta(especie, cuenta, precargar = false): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        const subpath = precargar ? 'precargar' : 'operar';
        return this._http
            .post(
                `${this._basePath}/${subpath}/especies/solicitud`,
                { ...especie, idCuenta: cuenta.idCuenta },
                { params: { avoidAuthRedirect: 'true' } }
            )
            .pipe(
                map((data: any) => {
                    data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                    return data;
                })
            );
    }

    confirmOperarEspecie(especie): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/especies/confirmar`, {
            ...especie,
            idCuenta: cuenta.idCuenta
        });
    }

    /* Precargar en true cuando se usa para generar una precarga desde asesores */
    confirmOperarEspecieCuenta(especie, cuenta, precargar = false): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        const subpath = precargar ? 'precargar' : 'operar';
        return this._http.post(`${this._basePath}/${subpath}/especies/confirmar`, {
            ...especie,
            idCuenta: cuenta.idCuenta
        });
    }

    confirmarOperarDolarOficial(payload, cuenta): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/monedas/confirmar`, { ...payload, idCuenta: cuenta.idCuenta });
    }

    operarDolarBolsa(dolar): Observable<any> {
        return this._http.post(`${this._basePath}/operar/dolarbolsa/solicitud`, { ...dolar }).pipe(
            map((data: any) => {
                data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                return data;
            })
        );
    }
    confirmarOperarDolarBolsa(dolar): Observable<any> {
        return this._http.post(`${this._basePath}/operar/dolarbolsa/confirmar`, { ...dolar });
    }

    operarDolarOficial(dolar): Observable<any> {
        return this._http.post(`${this._basePath}/operar/monedas/solicitud`, { ...dolar }).pipe(
            map((data: any) => {
                data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                return data;
            })
        );
    }

    propsOperar$(tipos = []): Observable<any> {
        return this._propsOperarSubject.asObservable().pipe(
            map(props => {
                const display = this._layoutService.getDisplay('app-operar-orden');
                const mode =
                    !['Depositos', 'Transferencias'].includes(props.tipo) && display.draggleable ? 'window' : 'drawer';
                const event = props.event || 'open';
                const message = props.message || '';
                return { ...props, mode, event, message };
            }),
            filter(props => tipos.length === 0 || tipos.includes(props.tipo))
        );
    }

    changePropsOperar(props: PropsOperar): void {
        const currentProps = this._propsOperarSubject.value;
        const _default = {
            event: props.event || 'open',
            title: props.headerTitle || '',
            idPrecarga: props.idPrecarga || null,
            body: props.body || null,
            sizeModal: props.sizeModal || '',
            palabraBold: props.palabraBold || null,
            panel: props.panel || null,
            tipo: props.tipo || 'Acciones',
            modalTitle: props.modalTitle || '',
            modalSubtitle: props.modalSubtitle || '',
            message: props.message || '',
            headerTitle: props.headerTitle || '',
            doubleLineString: props.doubleLineString || false,
            idOperacion: props.idOperacion || null,
            plazo: props?.plazo >= 0 ? props.plazo : null,
            z_index: props?.z_index || undefined,
            accion: props?.accion || '',
            idMoneda: props?.idMoneda || null,
            idMonedaReturn: props?.idMonedaReturn || null,
            volver: props?.volver || false,
            eventFrom: props?.eventFrom || '',
            licitacion: props?.licitacion || null,
            especie: props?.especie || null,
            infoOperacion: props?.infoOperacion || '',
            generateOpCode: props?.generateOpCode || false,
            savedProps: props?.savedProps || null,
            tickerBase: props?.tickerBase || '',
            secondaryMsg: props?.secondaryMsg || '',
            reportDeposit: props?.reportDeposit || false,
            activeTab: props?.activeTab || '',
            refreshData: props?.refreshData || false,
            noInstrumento: props?.noInstrumento || false,
            eliminarTickerWatchlist: props?.eliminarTickerWatchlist || null,
            esEliminarWatchlist: props?.esEliminarWatchlist || false,
            eliminarWatchlist: props?.eliminarWatchlist || null,
            showVolver: props?.showVolver || false,
            item: props?.item || null,
            tipoDeOrden: props?.tipoDeOrden || 'mercado',
            showRecomendations: props?.showRecomendations || false,
            precioCompra: props?.precioCompra || null,
            precioVenta: props?.precioVenta || null,
            modalHeaderTitle: props?.modalHeaderTitle || '',
            isFavoritos: props?.isFavoritos || false
        };

        const noOperationCode = !['1', 1].includes(this._authService.getCurrentUser()?.tieneC4D);

        const panelsThatNeedOpCode = ['ComprarDolarBolsa', 'VenderDolarBolsa', 'Packs', 'Transferencias'];

        if (noOperationCode && (panelsThatNeedOpCode.includes(props.tipo) || props.panel)) {
            this.changePropsOperar({
                tipo: 'modalInfo',
                doubleLineString: true,
                status: false,
                modalTitle: `No tenes el Código de Operación.`,
                modalSubtitle: 'Para seguir la operación tenes que registrar el código de 4 digitos.',
                headerTitle: 'Operar',
                info: true,
                generateOpCode: true,
                savedProps: { ...currentProps, ...props, ..._default }
            });
        } else {
            this._propsOperarSubject.next({ ...currentProps, ...props, ..._default });
        }
    }

    resetPropsOperar(): void {
        const currentProps = this._propsOperarSubject.value;
        this._propsOperarSubject.next({ ...currentProps, event: 'open' });
    }

    getFondos(panel?): Observable<any> {
        const idCuenta = this._accountsService.getCuentaSeleccionada()?.idCuenta.toString();
        return this._http
            .get(`${this._envService.env.BaseUrlService}/fondos`, { params: { idCuenta, panel } })
            .pipe(map(item => item['fondos']));
    }

    getTiposOferentesExternos(): Observable<any> {
        return this._http
            .get(`${this._envService.env.BaseUrlService}/oferentesexternos/tiposoperacion`)
            .pipe(map(data => data['tipos']));
    }

    operarOferentesExternos(transferencia): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/oferentesexternos/solicitud`, { ...transferencia }).pipe(
            map((data: any) => {
                data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                return data;
            })
        );
    }

    confirmarOperarOferentesExternos(transferencia): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/oferentesexternos/confirmar`, { ...transferencia });
    }

    operarTransferencia(transferencia): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/transferencias/solicitud`, { ...transferencia }).pipe(
            map((data: any) => {
                data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                return data;
            })
        );
    }

    operarTransferenciaCuenta(transferencia, cuenta): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/transferencias/solicitud`, { ...transferencia }).pipe(
            map((data: any) => {
                data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                return data;
            })
        );
    }

    confirmOperarTransferencia(transferencia): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/transferencias/confirmar`, {
            ...transferencia,
            idCuenta: cuenta.idCuenta
        });
    }

    confirmOperarTransferenciaCuenta(transferencia, cuenta): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/transferencias/confirmar`, {
            ...transferencia,
            idCuenta: cuenta.idCuenta
        });
    }

    operarFondo(fondo): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (fondo.idArchivo === '') {
            delete fondo.idArchivo;
        }
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http
            .post(`${this._basePath}/operar/fondos/solicitud`, { ...fondo, idCuenta: cuenta.idCuenta })
            .pipe(
                map((data: any) => {
                    data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                    return data;
                })
            );
    }

    operarFondoCuenta(fondo, cuenta, precargar = false): Observable<any> {
        if (fondo.idArchivo === '') {
            delete fondo.idArchivo;
        }
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        const subpath = precargar ? 'precargar' : 'operar';
        return this._http
            .post(`${this._basePath}/${subpath}/fondos/solicitud`, { ...fondo, idCuenta: cuenta.idCuenta })
            .pipe(
                map((data: any) => {
                    data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                    return data;
                })
            );
    }

    confirmOperarFondo(fondo): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/fondos/confirmar`, {
            ...fondo,
            idCuenta: cuenta.idCuenta
        });
    }

    confirmOperarFondoCuenta(fondo, cuenta, precargar = false): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        const subpath = precargar ? 'precargar' : 'operar';
        return this._http.post(`${this._basePath}/${subpath}/fondos/confirmar`, {
            ...fondo,
            idCuenta: cuenta.idCuenta
        });
    }

    operarPacks(payload, cuenta): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/sinteticos/solicitud`, payload).pipe(
            map((data: any) => {
                data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                return data;
            })
        );
    }

    confirmarOperarPacks(packs, cuenta): Observable<any> {
        if (!cuenta.idCuenta) {
            return EMPTY;
        }

        return this._http.post(`${this._basePath}/operar/sinteticos/confirmar`, {
            ...packs,
            idCuenta: cuenta.idCuenta
        });
    }

    getDetailPrecarga(idPrecarga, idCuenta?): Observable<any> {
        if (!idCuenta) {
            const cuenta = this._accountsService.getCuentaSeleccionada();
            if (!cuenta.idCuenta) {
                return EMPTY;
            } else {
                idCuenta = cuenta.idCuenta;
            }
        }
        return this._http
            .get(`${this._basePath}/precargar/obtener/${idCuenta}/${idPrecarga}`)
            .pipe(map(resp => resp['orden'][0]));
    }

    cancelarPrecarga(payload): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/precargar/cancelar`, { ...payload, idCuenta: cuenta.idCuenta });
    }

    getLicitaciones(payload): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/licitacion/detalle`, { ...payload, idCuenta: cuenta.idCuenta });
    }

    /* Precargar en true cuando se usa para generar una precarga desde asesores */
    operarLicitacion(payload, precargar = false): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        const subpath = precargar ? 'precargar' : 'operar';
        return this._http
            .post(`${this._basePath}/${subpath}/licitacion/solicitud`, {
                ...payload,
                idCuenta: cuenta.idCuenta
            })
            .pipe(
                map((data: any) => {
                    data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                    return data;
                })
            );
    }

    confirmarOperarLicitacion(payload, precargar = false): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        const subpath = precargar ? 'precargar' : 'operar';
        return this._http.post(`${this._basePath}/${subpath}/licitacion/confirmar`, {
            ...payload,
            idCuenta: cuenta.idCuenta
        });
    }

    getTiposTransferenciaTitulos(): Observable<any> {
        return this._http.get(`${this._basePath}/transf-titulos/emisor-receptor`);
    }

    getPaisesTransferenciaTitulos(): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.get(`${this._basePath}/transf-titulos/paises/${cuenta.idCuenta}`);
    }

    getAgentesTransferenciaTitulos(idPais): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.get(`${this._basePath}/transf-titulos/agentes/${cuenta.idCuenta}/${idPais}`);
    }

    uploadComprobanteTransferenciaTitulos(data): Observable<any> {
        return this._http.post<any[]>(`${this._basePath}/upload/documento/39`, data, {
            headers: {
                'Content-Type': data.type
            }
        });
    }

    operarTransferenciaTitulos(payload): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http
            .post(`${this._basePath}/operar/transf-titulos/solicitud`, {
                ...payload,
                idCuenta: cuenta.idCuenta
            })
            .pipe(
                map((data: any) => {
                    data.AlertaOperacion = replaceNewLineByBrTag(data.AlertaOperacion);
                    return data;
                })
            );
    }

    confirmarOperarTransferenciaTitulos(payload): Observable<any> {
        const cuenta = this._accountsService.getCuentaSeleccionada();
        if (!cuenta.idCuenta) {
            return EMPTY;
        }
        return this._http.post(`${this._basePath}/operar/transf-titulos/confirmar`, {
            ...payload,
            idCuenta: cuenta.idCuenta
        });
    }

    conversionRentas(payload): Observable<any> {
        return this._http.post(`${this._basePath}/operar/conversionrentas/solicitud`, payload);
    }

    operarConformidad(payload: Conformidad): Observable<any> {
        const _payload = {
            idCuenta: this._accountsService.getCuentaSeleccionada().idCuenta,
            ...payload
        };
        return this._http.post(`${this._basePath}/operar/conformidad`, _payload);
    }

    openModalInfo(props: ModalInfoProps): void {
        this.changePropsOperar({
            tipo: 'modalInfo',
            doubleLineString: props?.doubleLineString || false,
            status: props?.status || false,
            headerTitle: props?.headerTitle || '',
            refreshData: props?.refreshData || false,
            modalTitle: props?.modalTitle || '',
            secondaryMsg: props?.secondaryMsg || '',
            modalSubtitle: props?.modalSubtitle || '',
            info: props?.info
        });
    }
    operarConversionEspecies(payload) {
        return this._http.post(`${this._basePath}/operar/conversionespecies/solicitud`, payload);
    }
    confirmarOperarEspecie(payload) {
        return this._http.post(`${this._basePath}/operar/conversionespecies/confirmar`, payload);
    }
}
