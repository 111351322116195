import { Component, HostListener, OnInit } from '@angular/core';
import { ModalV2Config } from '../../shared/models/modal-v2.model';
import { ModalV2Service } from '../../core/services/modal-v2.service';
import { DeviceService } from '../../core/services/device.service';
import { Subscription } from 'rxjs';
import { trigger, style, animate, transition, query, animateChild } from '@angular/animations';

const MODAL_SIZES = new Map([
    ['xs', 420],
    ['sm', 500],
    ['md', 700],
    ['lg', 900],
    ['xl', 1100]
]);

/**
 * Componente que representa un modal con el template
 * que se le pase (y una configuracion adicional). En
 * caso de necesitar usarlo, ver el servicio de
 * ModalV2Service.
 */
@Component({
    selector: 'app-modal-v2',
    templateUrl: 'modal-v2.component.html',
    styleUrls: ['./modal-v2.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [style({ transform: 'translateX(100%)', opacity: 0 }), animate('300ms ease-in')]),
            transition(':leave', [animate('300ms ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))])
        ]),
        trigger('parentSlideInOut', [transition('* => void', [query('@*', [animateChild()], { optional: true })])])
    ]
})
export class ModalV2Component implements OnInit {
    show = false;
    config!: ModalV2Config;

    private _onCloseSubscription!: Subscription;

    get isMobile(): boolean {
        return this._deviceService.is(['mobile']);
    }

    get getBackColor(): string {
        return `bg-${this.config.backgroundColor}`;
    }

    get getOpacity(): string {
        return `opacity-${this.config.backgroundOpacity}`;
    }

    get getMaxHeight(): number {
        return this.config.type === 'modal' ? window.innerHeight - 20 : window.innerHeight;
    }

    get getWidth(): number {
        return MODAL_SIZES.get(this.config.size);
    }

    constructor(private _modalService: ModalV2Service, private _deviceService: DeviceService) {}

    @HostListener('window:keydown.escape', ['$event'])
    handleKeyDown() {
        this._modalService.close({});
    }

    ngOnInit(): void {
        this._modalService.onOpen.subscribe((config: ModalV2Config) => {
            this._setConfig(config);
            this.show = true;
            /** Me subscribo en caso de recibir un close de fuera. */
            this._onCloseSubscription = this._modalService.onClose.subscribe(_ => {
                this.show = false;
                this._onCloseSubscription.unsubscribe();
            });
        });
    }

    private _setConfig(config: ModalV2Config) {
        this.config = {
            type: 'sidebar',
            showBackground: true,
            backgroundColor: 'dark',
            backgroundOpacity: 2,
            backgroundContentModal: 'bg-white-default',
            closeOnBackDropClick: false,
            size: 'sm',
            wantModalBody: true,
            hideScrollbar: true,
            context: { modalV2Close: this.close },
            ...config
        };
        if (!this.config.context.modalV2Close) {
            this.config.context['modalV2Close'] = this.close;
        }
    }

    close = (args?: any): void => {
        this.show = false;
        this._onCloseSubscription.unsubscribe();
        this._modalService.close(args);
    };
}
