import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../services/environment.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConfiguracionService {
    private _basePath = this._envService.env.BaseUrlService + '/configuracion/usuario';

    constructor(private http: HttpClient, private _envService: EnvironmentService) {}

    getAllDevices(): Observable<any> {
        return this.http.get(`${this._basePath}?allDevices=1`);
    }

    toggleNotificacion(config): Observable<any> {
        return this.http.post(this._basePath, {
            Valor: config.Valor,
            idConfiguracionUsuario: config.idConfiguracionUsuario,
            idDispositivo: config.idDispositivo
        });
    }
}
