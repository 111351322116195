import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsInstrumentosService } from '../clients/utils-instrumentos.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class CotizacionesGuard implements CanActivate {
    constructor(
        private _utilsInstrumentosService: UtilsInstrumentosService,
        private _router: Router,
        private _envService: EnvironmentService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const type = route.routeConfig.path;

        if (!type) {
            // Para la ruta Padre /cotizaciones no hay firstChild definido, permitirlo
            return true;
        }

        const result = this._utilsInstrumentosService.verifyPermissions(type);
        if (!result) {
            // si no lo tiene en el arbol de permisos redirigir a /cotizaciones para resuelva la default
            this._router.navigate(['/app/deny']);
        }
        return this._utilsInstrumentosService.verifyPermissions(type);
    }
}
