import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { JWTdecode } from 'src/app/utils/jwt-decode';
import { OperarService, PropsOperar } from '../clients/operar.service';
import { BzUserBehaviourService } from '@balanz/services';
import { AuthService } from './auth.service';
import { UtilsInstrumentosService } from '../clients/utils-instrumentos.service';
import { CotizacionesClientService } from '../clients/cotizaciones-client.service';
import { CuentaService } from '../clients/cuenta.service';
@Injectable()
export class DeeplinkService {
    private _panelReady: BehaviorSubject<boolean>;

    constructor(
        private _operarService: OperarService,
        private _router: Router,
        private authService: AuthService,
        private _userBehaviourService: BzUserBehaviourService,
        private _utilsInstrumentosService: UtilsInstrumentosService,
        private _cotizacionesClientService: CotizacionesClientService,
        private _cuentaService: CuentaService
    ) {
        this._panelReady = new BehaviorSubject<boolean>(false);
    }

    redirect(data: object | string, eventoptfrom: string, fromLink?: boolean, deeplinkRedirect = '1') {
        const redirect = typeof data === 'string' ? JWTdecode(data) : data;
        if (redirect?.data) {
            if (redirect.data.share_id) {
                this._sendShareEvent(redirect);
            }

            if (redirect.data.campaignData) {
                this._sendCampaignEvent(redirect.data.campaignData);
            }

            const redirectSection = this._utilsInstrumentosService
                .getAllInstrumentos()
                .filter(item => item.namespace === redirect.data.namespace)[0]?.linkTabla;
            if (redirectSection) {
                this._router.navigateByUrl(redirectSection);
            } else {
                let namespace = redirect.data.namespace;

                if (namespace === 'Core.Business.DetalleInstrumento' && redirect.data.fondo) {
                    namespace = 'Core.Business.DetalleFondo';
                }

                let ticker;
                const deeplinkRedirected = deeplinkRedirect;
                switch (namespace) {
                    case 'Core.Business.LinkExterno':
                        const parsedUrl = new URL(redirect.data.URL);
                        if (
                            parsedUrl.host.endsWith('.balanz.com') ||
                            parsedUrl.host === 'balanz.com' ||
                            parsedUrl.host === 'balanzuniversity.com'
                        ) {
                            if (this.authService.getCurrentUser()) {
                                //si se llama un deeplink desde "misma solapa donde esta logueado lo abre como pop-up"
                                window.open(redirect.data.URL, '_blank', 'noopener=true');
                            } else {
                                location.href = redirect.data.URL;
                            }
                        } else {
                            this._router.navigateByUrl('/app/home');
                        }
                        break;
                    case 'Core.Business.ProtegerCuenta':
                        const nonceData = redirect.data.nonce;
                        const urlProteger =
                            location.protocol +
                            '//' +
                            location.host +
                            '/auth/protegercuenta?protegercuenta=' +
                            nonceData;
                        if (nonceData) {
                            if (this.authService.getCurrentUser()) {
                                //si se llama un deeplink desde "misma solapa donde esta logueado lo abre como pop-up"
                                window.open(urlProteger, '_blank', 'noopener=true');
                            } else {
                                location.href = urlProteger;
                            }
                        }

                        break;
                    case 'Core.Business.DetallePack':
                        ticker = redirect.data.detallePack || redirect.data.ticker;

                        if (ticker) {
                            this._router.navigate(['/app/detalleInvesment'], {
                                queryParams: { Ticker: ticker, eventoptfrom, deeplinkRedirected }
                            });
                        } else {
                            this._router.navigateByUrl('/app/home');
                        }

                        break;
                    case 'Core.Business.DetalleFondo':
                        ticker = redirect.data.fondo;

                        if (ticker) {
                            this._router.navigate(['/app/detalleFondos'], {
                                queryParams: { ticker, eventoptfrom, deeplinkRedirected }
                            });
                        } else {
                            this._router.navigateByUrl('/app/home');
                        }

                        break;
                    case 'Core.Business.DetalleInstrumento':
                        ticker = redirect.data.ticker;

                        if (ticker) {
                            const queryParams = { ticker, eventoptfrom, deeplinkRedirected };

                            if (redirect.data.idPlazo !== undefined) {
                                queryParams['idPlazo'] = redirect.data.idPlazo;
                            }

                            this._router.navigate(['/app/detalleinstrumento'], { queryParams });
                        } else {
                            this._router.navigateByUrl('/app/home');
                        }

                        break;
                    case 'Core.Business.EjecutarOrdenPrecargada':
                        this._openOperarPanel(fromLink, {
                            tipo: 'modalOrdenPrecarga',
                            headerTitle: 'global.components.modalTemplates.modalOrdenPrecarga.title.confirmar',
                            body: {
                                tipoAccion: 'Confirmar',
                                idOrden: redirect.data.idOrdenPrecargada
                            }
                        });
                        break;
                    case 'Core.Business.Operar.Licitaciones':
                        const _especie = isNaN(redirect.data.defaultEspecie)
                            ? -1
                            : parseInt(redirect.data.defaultEspecie, 10);
                        this._openOperarPanel(fromLink, {
                            tipo: 'Licitaciones',
                            idOperacion: parseInt(redirect.data.defaultTipoOperacion, 10),
                            licitacion: parseInt(redirect.data.defaultLicitacion, 10),
                            especie: _especie,
                            eventFrom: eventoptfrom
                        });
                        break;
                    case 'Core.Business.Operar.Licitaciones2':
                        this._openOperarPanel(fromLink, {
                            tipo: 'Licitaciones',
                            idOperacion: parseInt(redirect.data.defaultTipoOperacion, 10),
                            licitacion: parseInt(redirect.data.defaultLicitacion, 10),
                            especie: parseInt(redirect.data.defaultEspecie, 10),
                            eventFrom: eventoptfrom
                        });
                        break;
                    // Deeplinks OPERAR
                    case 'Core.Business.Operar.Especies':
                    case 'Core.Business.Operar.Futuros':
                    case 'Core.Business.Operar.Cauciones':
                    case 'Core.Business.Operar.FCI':
                        const panel = parseInt(redirect.data.panel, 10);
                        const especie = this._cotizacionesClientService.getTipoInstrumentoByPanel(panel);
                        this._openOperarPanel(
                            fromLink,
                            {
                                idOperacion: parseInt(redirect.data.idTipoOperacion, 10),
                                ticker: redirect.data.ticker,
                                plazo: parseInt(redirect.data?.idPlazo, 10),
                                panel: parseInt(redirect.data?.panel, 10),
                                instrumento: especie[0].name
                            },
                            true
                        );
                        break;
                    case 'Core.Business.Operar.DolarBolsa':
                        const _idTipoOperacion = redirect?.data?.idTipoOperacion ? redirect?.data.idTipoOperacion : 22;
                        const _type = _idTipoOperacion === 22 ? 'ComprarDolarBolsa' : 'VenderDolarBolsa';
                        this._openOperarPanel(fromLink, {
                            tipo: _type,
                            idOperacion: _idTipoOperacion,
                            eventFrom: eventoptfrom,
                            securityID: redirect.data?.SecurityID
                        });
                        break;
                    case 'Core.Business.Detalle':
                        this._router.navigateByUrl('/app/detalle');
                        break;
                    case 'Core.Business.Operar.Treasuries':
                        this._openOperarPanel(fromLink, {
                            tipo: 'US Treasuries',
                            instrumento: 'US Treasuries',
                            ticker: redirect.data.ticker,
                            plazo: parseInt(redirect.data.idPlazo, 10),
                            panel: parseInt(redirect.data.panel, 10),
                            idMoneda: parseInt(redirect.data.idMoneda, 10),
                            eventFrom: eventoptfrom
                        });
                        break;
                    default:
                        this._router.navigateByUrl('/app/home');
                        break;
                }
            }
        } else {
            this._router.navigateByUrl('/app/home');
        }
    }

    panelReady() {
        this._panelReady.next(true);
    }

    private _sendShareEvent(redirect) {
        this._userBehaviourService.sendEvent({
            category: 'share',
            name: 'share.share_ticker_watch',
            description: 'Event registered when an user watchs a shared a ticker',
            content: {
                ticker: redirect.data.ticker,
                share_id: redirect.data.share_id
            }
        });
    }

    private _sendCampaignEvent(campaignData) {
        this._userBehaviourService.sendEvent({
            category: 'campaigns',
            name: 'campaigns.login',
            description:
                'Event registered when an user clicks on the link of the campaign, arrives to clientes unlogged and logs in',
            content: {
                ...campaignData
            },
            behaviourIdConfig: 'current'
        });
    }

    private _openOperarPanel(fromLink: boolean, props: PropsOperar, validarPanel = false) {
        if (fromLink) {
            this._router.navigateByUrl('/app/home').finally(() => {
                this._panelReady.subscribe(ready => {
                    if (ready) {
                        if (validarPanel) {
                            const _cuenta = this._cuentaService.getCuentaSeleccionada();
                            const isBCI = _cuenta.idNegocio === 5;
                            const panel = props.panel;
                            if (!this._utilsInstrumentosService.validatePanel(panel, isBCI)) {
                                return;
                            }
                        }
                        this._operarService.changePropsOperar(props);
                    }
                });
            });
        } else {
            this._operarService.changePropsOperar(props);
        }
    }
}
