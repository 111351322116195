import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { BzStorageService } from '@balanz/services';
import { getDeviceByWidth } from '../../utils/device';
import { DeviceService } from './device.service';

interface Size {
    width: number | string;
    height: number | string;
}

interface Position {
    x: number | string;
    y: number | string;
}

interface Distribution {
    page: string;
    layout: string;
}

interface Display {
    draggleable: boolean;
    position: Position;
    size: Size;
}

export type LayoutMenuType = 'show' | 'mini' | 'hide';

const ROUTES_WITH_LAYOUT = ['home'];

@Injectable()
export class LayoutService {
    private distributionSubject$: BehaviorSubject<Map<string, Distribution>>;
    private displayPositionsSubject$: BehaviorSubject<Map<string, Display>>;
    private menuSubject$: BehaviorSubject<LayoutMenuType>;

    constructor(private _storageService: BzStorageService, private _deviceService: DeviceService) {
        this.setDefaultDistributions();
        this.setDefaultDisplay();
        this.setDefaultMenu();
        this.onDeviceChanges();
    }

    setDefaultMenu(): void {
        const device = getDeviceByWidth(window.innerWidth);
        this.menuSubject$ = new BehaviorSubject<LayoutMenuType>(this._defaultMenu(device));
    }

    onDeviceChanges(): void {
        this._deviceService.onDeviceChanged().subscribe((device: string) => {
            this.menuSubject$.next(this._defaultMenu(device));
        });
    }

    setDefaultDistributions(): void {
        const distributions = this._storageService.get('layout_distributions') || {};
        this.distributionSubject$ = new BehaviorSubject<Map<string, Distribution>>(distributions);
    }

    setDefaultDisplay(): void {
        const displayPositions = this._storageService.get('display_positions') || {};
        Object.keys(displayPositions).forEach(
            key => (this.displayPositions[key] = this.sanitizeDisplay(displayPositions[key]))
        );
        this.displayPositionsSubject$ = new BehaviorSubject<Map<string, Display>>(displayPositions);
    }

    displayPositions(component?: string): Observable<Display> {
        return this.displayPositionsSubject$.asObservable().pipe(
            map((item: Map<string, Display>) => {
                if (!component) {
                    return item;
                }

                if (item[component]) {
                    return this.sanitizeDisplay(item[component]);
                }

                return {
                    draggleable: false,
                    position: { x: 10, y: 10 },
                    size: { width: 300, height: 400 }
                };
            }),
            filter(item => item)
        );
    }

    addOrUpdateDisplayPosition(key: string, display: Display): void {
        const displayPositions = this._storageService.get('display_positions') || {};
        const newDisplayPositions = { ...displayPositions, [key]: display };
        this._storageService.set('display_positions', newDisplayPositions);
        this.displayPositionsSubject$.next(newDisplayPositions);
    }

    getDisplay(key): any {
        return (
            this.displayPositionsSubject$.value[key] || {
                draggleable: false,
                position: { x: 10, y: 10 },
                size: { width: 300, height: 400 }
            }
        );
    }

    getDistribution(page): Distribution {
        return this.distributionSubject$.value[page];
    }

    sanitizeDisplay(display): any {
        const positionDefault = { x: 10, y: 10 };
        const sizeDefault = { width: 300, height: 400 };
        let { position, size } = display;
        position = position || positionDefault;
        size = size || sizeDefault;
        const scale = 0.9;
        const maxWidth = window.screen.width * scale;
        const maxHeight = window.screen.height * scale;
        size.width = size.width < sizeDefault.width ? sizeDefault.width : size.width;
        size.height = size.height < sizeDefault.height ? sizeDefault.height : size.height;
        size.width = size.width > maxWidth ? maxWidth : size.width;
        size.height = size.width > maxHeight ? maxHeight : size.height;
        position.x = position.x < 0 ? 0 : position.x;
        position.y = position.y < 0 ? 0 : position.y;
        const maxOffsetX = window.screen.width - size.width;
        const maxOffsetY = window.screen.height - size.height;
        position.x = position.x >= maxOffsetX ? maxOffsetX : position.x;
        position.y = position.y >= maxOffsetY ? maxOffsetY : position.y;
        return { ...display, position, size };
    }

    distribution$(page?: string): Observable<Map<string, Distribution>> {
        if (!ROUTES_WITH_LAYOUT.includes(page) && page) {
            return EMPTY;
        }
        return this.distributionSubject$.asObservable().pipe(
            map((item: any) => {
                if (page) {
                    return item[page];
                }
                return item;
            }),
            filter((item: any) => item)
        );
    }

    addOrUpdateDistributionsLayout(distribution: Distribution): void {
        const distributions: Map<string, Distribution> = this._storageService.get('layout_distributions') || {};
        distributions[distribution.page] = distribution;
        this._storageService.set('layout_distributions', distributions);
        this.distributionSubject$.next(distributions);
    }

    layoutAvailable(route) {
        return ROUTES_WITH_LAYOUT.includes(route);
    }

    menu$(): Observable<LayoutMenuType> {
        return this.menuSubject$.asObservable().pipe(
            map((menu: LayoutMenuType) => {
                const device = getDeviceByWidth(window.innerWidth);
                if (device === 'desktop' || device === 'small-desktop') {
                    if (menu !== 'show') {
                        document.documentElement.style.setProperty('--width-menu', '70px');
                    } else {
                        document.documentElement.style.setProperty('--width-menu', '230px');
                    }
                } else {
                    if (menu !== 'show') {
                        document.documentElement.style.setProperty('--width-menu', '0px');
                    } else {
                        document.documentElement.style.setProperty('--width-menu', '280px');
                    }
                }
                return menu;
            })
        );
    }

    toggleMenu(): void {
        const device = getDeviceByWidth(window.innerWidth);
        const currentMenu: LayoutMenuType = this.menuSubject$.value;
        if (device === 'desktop' || device === 'small-desktop') {
            if (currentMenu === 'show') {
                this.menuSubject$.next('mini');
            } else {
                this.menuSubject$.next('show');
            }
        } else {
            if (currentMenu === 'show') {
                this.menuSubject$.next('hide');
            } else {
                this.menuSubject$.next('show');
            }
        }
    }

    private _defaultMenu(device): LayoutMenuType {
        switch (device) {
            case 'desktop':
                return 'mini';
            case 'small-desktop':
                return 'mini';
            default:
                return 'hide';
        }
    }
}
