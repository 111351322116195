import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';
export interface SubcarteraData {
    idInstrumento: number;
    Ticker: string;
    TipoInstrumento: number;
}

export interface SubCarteraResponse {
    subcarteras: SubcarteraData[];
}

export interface TenenciaSubcartera {
    Fecha: string;
    Tenencia: number;
    TipoInstrumento?: number;
}
export interface TenenciaSubcarteraResponse {
    TenenciaSubcarteras: TenenciaSubcartera[];
}

@Injectable()
export class SubCarteraService {
    private _basePath = 'https://localhost:3000';

    constructor(private _http: HttpClient, private _envService: EnvironmentService) {
        this._basePath = `${this._envService.env.BaseUrlService}/subcartera`;
    }

    getTenencia(
        tenenciaId: string,
        from: Date,
        to: Date,
        idMoneda: number,
        agrouped: string,
        idInstrumentos: string[] = []
    ): Observable<TenenciaSubcarteraResponse> {
        const auxInstruments = idInstrumentos.length ? `&idistrumentos=${idInstrumentos.join(',')}` : '';
        return this._http.get<TenenciaSubcarteraResponse>(
            `${this._basePath}/tenencia/${tenenciaId}?FechaDesde=${this._formatDate(from)}` +
                `&FechaHasta=${this._formatDate(to)}&idMoneda=${idMoneda}&ta=${agrouped}${auxInstruments}`
        );
    }

    getTickers(tickerId: string, from: Date, to: Date): Observable<SubCarteraResponse> {
        return this._http.get<SubCarteraResponse>(
            `${this._basePath}/tickers/${tickerId}?FechaDesde=${this._formatDate(from)}&FechaHasta=${this._formatDate(
                to
            )}`
        );
    }

    private _formatDate(date: Date): string {
        const d = this._toFullDate(date.getDate());
        const m = this._toFullDate(date.getMonth() + 1);
        const y = date.getFullYear();
        return `${y}${m}${d}`;
    }

    private _toFullDate(number: number): string {
        return number < 10 ? `0${number}` : String(number);
    }
}
