import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { DeviceType, getDeviceByWidth } from '../../utils/device';

@Injectable()
export class DeviceService {
    private _deviceSubject: BehaviorSubject<DeviceType>;

    constructor() {
        const device = getDeviceByWidth(window.innerWidth);
        this._deviceSubject = new BehaviorSubject<DeviceType>(device);
        this._onWindowsResize();
    }

    /**
     * Retorna true en caso de que uno de los tipos del array pasado por
     * parametro sea el dispositivo actual.
     */
    is(types: DeviceType[]): boolean {
        return types.some((t: DeviceType) => t === this.currentDevice());
    }

    /**
     * Retorna un observable que informa cuando se produce un cambio
     * de valor de device.
     */
    onDeviceChanged(): Observable<DeviceType> {
        return this._deviceSubject.asObservable();
    }

    /**
     * Retorna el valor de device actual.
     */
    currentDevice(): DeviceType {
        return this._deviceSubject.value;
    }

    private _onWindowsResize(): void {
        fromEvent(window, 'resize').subscribe((_: Event) => {
            const device = getDeviceByWidth(window.innerWidth);
            if (this.currentDevice() !== device) {
                this._deviceSubject.next(device);
            }
        });
    }
}
