import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TrackerService } from './tracker.service';

function onAppInit() {
    return () => new Promise((resolve, _) => resolve(true));
}

@NgModule()
export class BalanzTrackerModule {
    static forRoot(): ModuleWithProviders<BalanzTrackerModule> {
        return {
            ngModule: BalanzTrackerModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    deps: [TrackerService],
                    useFactory: onAppInit,
                    multi: true
                }
            ]
        };
    }

    static forChild(): ModuleWithProviders<BalanzTrackerModule> {
        return {
            ngModule: BalanzTrackerModule,
            providers: [
                {
                    provide: BalanzTrackerModule,
                    useValue: {}
                }
            ]
        };
    }
}
