import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../services/environment.service';
import { BehaviorSubject, Observable } from 'rxjs';

export interface TiposDocumentos {
    Descripcion: string;
    idTipoDocumento: number;
}

export interface ParametrosResponse {
    tiposDocumentos: TiposDocumentos[];
}

@Injectable()
export class ParametrosService {
    private _basePath = this._envService.env.BaseUrlService + '/comun/parametros';
    private _baseUrlService = this._envService.env.BaseUrlService;

    private tiposDocumentosSubject: BehaviorSubject<TiposDocumentos[]>;

    tiposDeDocumentos$(): Observable<TiposDocumentos[]> {
        return this.tiposDocumentosSubject.asObservable();
    }

    constructor(private http: HttpClient, private _envService: EnvironmentService) {
        this.tiposDocumentosSubject = new BehaviorSubject([]);
    }

    loadBussinessParams(): void {
        this.http.get(this._basePath).subscribe((response: ParametrosResponse) => {
            this.tiposDocumentosSubject.next(response.tiposDocumentos || []);
        });
    }

    getBussinessParams() {
        return this.http.get(this._basePath);
    }

    setParametroPersona(payload) {
        return this.http.post(`${this._baseUrlService}/parametrospersona`, payload);
    }
}
