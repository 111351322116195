<div id="menu" class="menu-bg-color menu top-most">
    <div class="menu-content top-most">
        <div class="centrado-bottom">
            <div class="menu-body">
                <div class="pt-3 border-top">
                    <app-modal [hideHeader]="true" [hideFooter]="true" [show]="true">
                        <div class="text-center bg-color-default text-color-default p-2 p-lg-3">
                            <span class="text-color-default text-size-6 "
                                >Hay una nueva versión disponible. ¿Querés actualizar?</span
                            >
                            <div class="mt-4 d-flex justify-content-center">
                                <button
                                    (click)="closeModal()"
                                    class="btn btn-sm btn-secondary me-3 text-color-primary bg-white-default border-white shadow-sm"
                                >
                                    Ahora no
                                </button>
                                <button
                                    class="btn btn-sm primary-buttons text-size-4 boton-si shadow-sm"
                                    (click)="doRefresh()"
                                >
                                    Si
                                </button>
                            </div>
                        </div>
                    </app-modal>
                </div>
            </div>
        </div>
    </div>
</div>
