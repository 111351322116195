import { Component, EventEmitter, Output } from '@angular/core';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { DeviceService } from 'src/app/core/services/device.service';

@Component({
    selector: 'app-refresh-modal',
    styleUrls: ['./refresh.component.scss'],
    templateUrl: './refresh.component.html'
})
export class RefeshModalComponent {
    @Output() closeEvent = new EventEmitter<boolean>();
    @Output() updateEvent = new EventEmitter<boolean>();

    get isResponsive(): boolean {
        return this._deviceService.is(['mobile', 'tablet']);
    }

    constructor(private _deviceService: DeviceService, private _envService: EnvironmentService) {}

    getCompleteImgUrl(img: string): string {
        return this._envService.env.assetsUrl + img;
    }

    doRefresh(): void {
        this.updateEvent.emit();
    }

    closeModal(): void {
        this.closeEvent.emit();
    }
}
