import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class TransferenciasService {
    private _basePath = this._envService.env.BaseUrlService;

    constructor(private http: HttpClient, private _envService: EnvironmentService) {}

    solicitudTransferencia(data): Observable<any> {
        const url = this._basePath + '/operar/transferencias/solicitud';
        return this.http.post<any[]>(url, data);
    }

    informarDeposito(data): Observable<any> {
        const url = this._basePath + '/operar/depositos/informar';
        return this.http.post<any[]>(url, data);
    }

    uploadComprobante(data): Observable<any> {
        const url = this._basePath + '/upload/comprobante';
        return this.http.post<any[]>(url, data, {
            headers: {
                'Content-Type': data.type
            }
        });
    }
}
