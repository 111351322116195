import { Injectable, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { CuentaService } from './cuenta.service';

export interface ReporteDescarga {
    value: string;
    key: string;
}

@Injectable()
export class DescargaReportesService {
    constructor(private _authService: AuthService, private _cuentaService: CuentaService) {}

    get tieneReportes(): any {
        return !!this.getLisadoReportes()?.length;
    }

    getLisadoReportes(): ReporteDescarga[] {
        const _reportes = [];
        if (this._authService.getPermissionById('ResumenMensual')) {
            _reportes.push({
                key: 'Cuenta corriente',
                value: '1'
            });
        }
        if (this._authService.getPermissionById('realizadoLotes')) {
            _reportes.push({
                key: 'Resultados del período',
                value: '2'
            });
        }
        if (this._authService.getPermissionById('posicionConsolidada')?.opciones?.exportarPosicionConsolidada) {
            _reportes.push({
                key: 'Posición consolidada',
                value: '3'
            });
        }
        return _reportes;
    }
}
