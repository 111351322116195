import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, delay, retryWhen, take, tap } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { LoggerService } from './logger.service';

@Injectable()
export class TickersService {
    private _basePath = `${this._envService.env.assetsTickersURL}/tickers/logoTicker.json`;
    private RETRY_TIME = 30000; //30 seg
    private RETRY_COUNT = 4;

    constructor(
        private _http: HttpClient,
        private _loggerService: LoggerService,
        private _envService: EnvironmentService
    ) {}

    /** Usar un replay subject a modo de cache. */

    private _subject = new ReplaySubject<{ [key: string]: string }>();
    private _status: 'init' | 'loaded' | 'loading';

    getTickerDiccionary(): Observable<{ [key: string]: string }> {
        if (!['loaded', 'loading'].includes(this._status)) {
            this._status = 'loading';
            this._http
                .get<{ [key: string]: string }>(this._basePath, { params: { avoidAuthRedirect: true, t: Date.now() } })
                .pipe(
                    catchError(error => {
                        //loggear error silencioso
                        this._loggerService.registrarEvento({
                            type: 'application',
                            description: 'errorTickersNull',
                            detail: 'Error obteniendo diccionario LogoTickers: ' + error.response
                        });
                        return throwError(error.response);
                    }),
                    retryWhen(errors => errors.pipe(delay(this.RETRY_TIME), take(this.RETRY_COUNT)))
                )
                .subscribe(result => {
                    this._status = 'loaded';
                    this._subject.next(result);
                    this._subject.complete();
                });
        }
        return this._subject.asObservable();
    }
}
