/** El método calcularVarAbsoluta puede devolver el resultado fragmentado a los decimales necesarios.
 * decimales = -1 - Devuelve el valor Absoluto
 * decimales > 0 - Devuelve el valor con los decimales declarados.
 * si no se le envia nada por default = 2
 */
export const calcularVarAbsoluta = (cot, decimales?): number => {
    const absoluto = cot?.u - cot?.ant;
    if (
        !cot?.ant ||
        cot?.ant === 0 ||
        cot?.ant === -1 ||
        !cot?.u ||
        cot?.u === 0 ||
        cot?.u === -1 ||
        cot?.f.length === 10
    ) {
        return 0;
    }
    return Number(decimales === -1 ? absoluto : absoluto.toFixed(decimales || 2));
};

export const calcularVarPorcentual = cot => Number(((calcularVarAbsoluta(cot, -1) / cot?.ant) * 100).toFixed(2));
