import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { EnvironmentService } from '../services/environment.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PriceAlertsService {
    private _basePath = this._envService.env.BaseUrlService;
    private _maxAlerts: number;

    constructor(private http: HttpClient, private authService: AuthService, private _envService: EnvironmentService) {}

    getAlertasPrecios(): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.get(`${this._basePath}/pricealerts/alertsbyuser?idpersona=${auth.idPersona}`);
    }

    getConditions(): Observable<any> {
        return this.http.get(`${this._basePath}/pricealerts/conditions`);
    }

    getParameters(): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.get(`${this._basePath}/pricealerts/parameters?idpersona=${auth.idPersona}`);
    }

    getMaxAlerts(): Observable<number> {
        return new Observable(observer => {
            if (this._maxAlerts) {
                observer.next(this._maxAlerts);
                return observer.complete();
            }
            const auth = this.authService.getCurrentUser();
            this.http
                .get(`${this._basePath}/pricealerts/parameters?idpersona=${auth.idPersona}`)
                .pipe(map((res: any) => res.extra_data.max_alerts))
                .subscribe((max: number) => {
                    this._maxAlerts = max;
                    observer.next(this._maxAlerts);
                    observer.complete();
                });
        });
    }

    createAlerta(alerta): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.post(
            `${this._basePath}/pricealerts/setalert`,
            {
                idpersona: auth.idPersona,
                ...alerta
            },
            { params: { avoidAuthRedirect: 'true' } }
        );
    }

    updateAlerta(alerta): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.put(`${this._basePath}/pricealerts/setalert`, {
            idpersona: auth.idPersona,
            ...alerta
        });
    }

    deleteAlerta(id): Observable<any> {
        const auth = this.authService.getCurrentUser();
        return this.http.delete(`${this._basePath}/pricealerts/deletealert`, {
            params: {
                idpersona: auth.idPersona,
                id
            }
        });
    }
}
