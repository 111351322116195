import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsInstrumentosService } from '../clients/utils-instrumentos.service';

@Injectable()
export class DetallesGuard implements CanActivate, CanLoad {
    constructor(private _utilsInstrumentosService: UtilsInstrumentosService, private _router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const type = route.firstChild.routeConfig.path;
        const result = this._utilsInstrumentosService.verifyPermissions(type);
        if (!result) {
            this._router.navigate(['/home']);
        }
        return this._utilsInstrumentosService.verifyPermissions(type);
    }

    canLoad(
        __: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let type = '';
        let result = false;
        const urlType = segments[0].path;
        if (urlType?.toLowerCase() === 'detalleinvesment') {
            type = 'Investment Ideas';
            result = this._utilsInstrumentosService.verifyPermissions(type, true);
        } else if (urlType?.toLowerCase() === 'detallefondos') {
            type = 'Fondos';
            result = this._utilsInstrumentosService.verifyPermissions(type, true);
        } else if (urlType?.toLowerCase() === 'detallerealestate') {
            type = 'Real Estate';
            result = this._utilsInstrumentosService.verifyPermissions(type, true);
        }
        if (!result) {
            this._router.navigate(['/home']);
        }
        return result;
    }
}
